/**
 * A standard client-side router error (e.g., page not found).
 * This is returned by the useRouteError() hook.
 * @see useRouteError
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface RouteError {
	/**
	 * The HTTP status code (e.g., 404).
	 * @example 404
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	readonly status: number

	/**
	 * The HTTP status message (e.g., Not Found).
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	readonly statusText: string

	/**
	 * A message describing the error.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	readonly data: string

	/**
	 * The internal Error.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	readonly error: Error
}

/**
 * Type guard to ensure the value is a standard client-side router error.
 * This is required as custom errors can be thrown too.
 * @param error The error returned by the useRouteError() hook.
 * @returns Whether the error is a standard client-side router error.
 * @example if (isRouteError(useRouteError())) { ...
 * @see https://stackoverflow.com/a/76126878
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isRouteError = (error: unknown): error is RouteError => {
	// Check it's unset
	if (error === null || error === undefined) return false

	// Check it's an object
	if (typeof error !== "object") return false

	// Check the properties exist & their types are correct
	if (!("status" in error) || typeof error.status !== "number") return false
	if (!("statusText" in error) || typeof error.statusText !== "string") return false
	if (!("data" in error) || typeof error.data !== "string") return false
	if (!("error" in error) || !(error.error instanceof Error)) return false

	return true
}
