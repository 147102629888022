import type { ComponentPropsWithRef, JSX } from "react"

/**
 * A standard HTML anchor element.
 * @param params The component parameters. The <a> target & rel props are fixed.
 * @returns The React component.
 * @example <URL href="https://handi-app.co.uk" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Anchor = ({ ...props }: Omit<ComponentPropsWithRef<"a">, "rel" | "target">): JSX.Element => (
	<a {...props} target="_blank" rel="noopener noreferrer" />
)

export default Anchor
