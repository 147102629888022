import { useReducer, type AnyActionArg, type ComponentPropsWithRef, type JSX } from "react"

/**
 * A standard HTML image element that smoothly fades in.
 * @param params The component parameters. The <img> loading, onLoad & referrerPolicy props are fixed.
 * @returns The React component.
 * @example <BackgroundImage src="/images/backgrounds/hand-painting-child.webp" alt="A child with paint on their hands." />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Image = ({
	...props
}: Omit<ComponentPropsWithRef<"img">, "loading" | "onLoad" | "referrerPolicy">): JSX.Element => {
	const [isReady, onLoad] = useReducer<boolean, AnyActionArg>(() => true, false)

	return (
		<img
			{...props}
			title={props.title ?? props.alt}
			aria-label={props["aria-label"] ?? props.alt}
			referrerPolicy="no-referrer"
			loading="lazy"
			onLoad={onLoad}
			className={`rounded-2xl duration-image ${isReady ? "opacity-100" : "cursor-wait opacity-0"} ${props.className ?? ""}`.trimEnd()}
		/>
	)
}

export default Image
