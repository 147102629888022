import { ContentBlockTypes, type ContentBlock, type ContentBlocks } from "~/types/api/common/content-block"

/**
 * A content block that contains an embedded map.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Map extends ContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.Map

	/**
	 * The display text for the map.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	mapTitle: string

	/**
	 * @todo I assume this is some sort of Google Maps embed URL, but I'm not sure.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	map: unknown
}

/**
 * Ensures the given content block is a map block.
 * @param block Any content block.
 * @returns Whether the content block is a map block.
 * @example if (isMapContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isMapContentBlock = (block: ContentBlocks): block is Map => block.type === ContentBlockTypes.Map
