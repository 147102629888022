import type { JSX } from "react"
import { useLocation } from "react-router-dom"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const BASE_URL = import.meta.env.VITE_BASE_URL
if (BASE_URL === undefined || BASE_URL === "") throw new Error("The base URL is missing!")

/**
 * A component for changing the metadata of the page.
 * This is a React 19 feature that allows for transparent manipulation of the HTML <head> element.
 * @param params The component parameters.
 * @param params.title The title of the page. This will be prepended to the app name.
 * @param params.description The description of the page.
 * @param params.path The path of the page. Defaults to the base URL.
 * @param params.lastModifiedAt The ISO 8601 timestamp of when the page was last modified. Defaults to the Vite build time.
 * @param params.shouldIndex Whether to prevent search engines from indexing the page.
 * @returns The React component that will be hoisted into the HTML <head> element.
 * @example <Metadata title="Select Trust" />
 * @see https://react.dev/blog/2024/04/25/react-19#support-for-metadata-tags
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Metadata = ({
	title,
	description = "Paediatric health and illness guidance based on UK NHS guidance.",
	path,
	lastModifiedAt = VITE_BUILT_AT,
	shouldIndex = true
}: {
	title: string
	description?: string
	path?: string
	lastModifiedAt?: string
	shouldIndex?: boolean
}): JSX.Element => {
	const { pathname } = useLocation()

	return (
		<>
			<title>{`${title} - HANDi Paediatrics`}</title>

			{/* Metadata */}
			<meta name="description" content={description} />
			<meta name="subject" content={description} />
			<meta name="last-modified" content={lastModifiedAt} />

			{/* OpenGraph */}
			<meta property="og:url" content={`${BASE_URL}${path ?? pathname}`} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:updated_time" content={lastModifiedAt} />

			{/* Twitter */}
			<meta name="twitter:title" content={`${title} - HANDi Paediatrics`} />
			<meta name="twitter:description" content={description} />

			{/* Search Engine Indexing */}
			<link rel="canonical" href={`${BASE_URL}${path ?? pathname}`} />
			{shouldIndex ? (
				<meta name="robots" content="index, follow, archive" />
			) : (
				<meta name="robots" content="noindex, nofollow, noarchive" />
			)}
		</>
	)
}

export default Metadata
