import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import { orNull } from "~/helpers/primitives"
import type { AnyRole } from "~/types/api/roles"

/**
 * Keys for persisting selections in the browser's local storage.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export enum LocalStorageKeys {
	Site = "site-selection",
	Role = "role-selection"
}

/**
 * The user's selections.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Selections {
	/**
	 * The user's NHS trust selection.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	site: string | null

	/**
	 * The user's role selection.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	role: AnyRole | null
}

/**
 * A Redux slice for storing the user's selections.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const slice = createSlice({
	name: "selections",
	initialState: (): Selections => {
		const site = orNull(window.localStorage.getItem(LocalStorageKeys.Site.valueOf()))
		const role = orNull(window.localStorage.getItem(LocalStorageKeys.Role.valueOf()))

		if (!["hospital", "parents", "professionals", null].includes(role)) {
			console.warn(`Invalid role selection in local storage: '${role?.toString() ?? "Unknown"}'`)

			return {
				site,
				role: null
			}
		}

		return { site, role: role as Selections["role"] }
	},
	reducers: {
		updateSite: (state, action: PayloadAction<Selections["site"]>): Selections => ({
			...state,
			site: action.payload
		}),
		updateRole: (state, action: PayloadAction<Selections["role"]>): Selections => ({
			...state,
			role: action.payload
		})
	}
})

/**
 * A slice action for updating the user's NHS trust selection.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const { updateSite } = slice.actions

/**
 * A slice action for updating the user's role selection.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const { updateRole } = slice.actions

/**
 * The slice's reducer for the user's selections.
 * This should be added to the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export default slice.reducer
