import { useCallback, useMemo, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"

import Button from "~/components/standard/button"
import Image from "~/components/standard/image"
import { logFirebaseAnalyticsEvent } from "~/helpers/analytics"
import { chunk, orNull } from "~/helpers/primitives"
import { useCraftEntries } from "~/hooks/use-craft-entries"
import { useRoleSelection, useSiteSelection } from "~/hooks/use-selections"
import { generateCraftEntryRoute } from "~/router/routes"
import { useReduxSelector } from "~/state/store"
import type { Link, Links } from "~/types/api/content-blocks/links"
import type { CraftEntryState } from "~/types/state"

import ChevronIcon from "~/assets/icons/chevron.svg?react"
import DownloadIcon from "~/assets/icons/download.svg?react"
import PhoneIcon from "~/assets/icons/phone.svg?react"

/* eslint-disable @typescript-eslint/naming-convention */

const LinkButton = ({
	block,
	link,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "label" | "onClick"> & { block: Links; link: Link }): JSX.Element => {
	const navigate = useNavigate()

	const siteHandle = useSiteSelection()
	const roleHandle = useRoleSelection()

	// Re-used from breadcrumbs :)
	const currentEntryId = useReduxSelector(({ breadcrumbs }) => breadcrumbs.currentEntryId)

	const { partialEntry, isReady } = useCraftEntries({
		siteHandle,

		filter: ({ section, entry_id }) =>
			section === roleHandle && entry_id === parseInt(link.theLink.value ?? "", 10),
		skip: link.type !== "entry" || roleHandle === null
	})

	/* eslint-disable @typescript-eslint/no-unnecessary-condition */
	const isBroken = useMemo<boolean>(() => {
		if (link.type === "tel") return orNull(link.theLink.value) === null
		if (link.type === "asset" || link.type === "url") return orNull(link.url) === null
		if (link.type === "entry") {
			if (orNull(link.theLink.value) === null) return true
			if (isReady && partialEntry === null) return true

			const entryId = parseInt(link.theLink.value as string, 10)
			if (isNaN(entryId)) return true

			if (entryId === currentEntryId) return true

			return false
		}

		return false // Assume we're fine
	}, [partialEntry, isReady, currentEntryId, link])

	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (isBroken) return // Don't do anything if the link is broken

		if (link.type === "tel") {
			if (link.theLink.value === null) {
				console.warn(`No number for telephone link '${link.text}'?!`)
				return
			}

			logFirebaseAnalyticsEvent("select_content", {
				content_type: "tel",
				content_id: link.theLink.value
			})

			window.location.href = `tel:${link.theLink.value}`
			return
		}

		if (link.type === "asset" || link.type === "url") {
			if (link.url === null) {
				console.warn(`No URL for external/asset link '${link.text}'?!`)
				return
			}

			logFirebaseAnalyticsEvent("select_content", {
				content_type: "url",
				content_id: link.url
			})

			window.open(link.url, "_blank", "noopener noreferrer")
			return
		}

		if (link.theLink.value === null) {
			console.warn(`No target Craft CMS entry ID for link '${link.text}'?!`)
			return
		}

		if (siteHandle === null) {
			console.warn("No site selection yet?!")
			return
		}

		if (roleHandle === null) {
			console.warn("No role selection yet?!")
			return
		}

		if (partialEntry === null) {
			console.warn(`No partial Craft CMS entry found for link pointing to ID '${link.theLink.value}'?!`)
			return
		}

		logFirebaseAnalyticsEvent("select_content", {
			content_type: "entry",
			content_id: link.theLink.value
		})

		navigate(generateCraftEntryRoute(siteHandle, roleHandle, partialEntry.slug), {
			state: {
				partialEntry
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, siteHandle, roleHandle, partialEntry, isBroken, link])

	const is999 = link.type === "tel" && link.theLink.value === "999"
	const is111 = link.type === "tel" && link.theLink.value === "111"

	let buttonClasses = "text-logo-purple bg-purple-alt/15 hover:bg-purple-alt/25 active:bg-purple-alt/35"
	if (is999) buttonClasses = "text-white bg-algorithm-red hover:bg-algorithm-red/90 active:bg-algorithm-red/80"
	else if (is111)
		buttonClasses = "text-white bg-algorithm-amber hover:bg-algorithm-amber/90 active:bg-algorithm-amber/80"

	let iconClasses = "fill-logo-purple"
	if (is999) iconClasses = "fill-algorithm-red"
	else if (is111) iconClasses = "fill-algorithm-amber"

	return (
		<Button
			{...props}
			label={link.text}
			onClick={onClick}
			disabled={isBroken}
			// TODO: Label doesn't vertically align with telephone icon
			className={`gap-y-2 rounded-xl p-3 px-4 text-left ${!isBroken ? buttonClasses : "bg-control-disabled-background"} ${is999 || is111 ? `justify-center font-bold ${link.text.length < 10 ? "text-2xl" : ""}` : ""} ${props.className ?? ""}`.trimEnd()}
			icon={
				<div
					className={`aspect-square overflow-hidden ${block.columnLayout === true ? "h-12 min-h-12 w-12 min-w-12" : "h-9 min-h-9 w-9 min-w-9"} ${link.type === "tel" ? "rounded-full bg-white p-2" : ""}`.trimEnd()}>
					{/* eslint-disable no-nested-ternary */}
					{link.type === "tel" ? (
						<PhoneIcon
							className={`h-full w-full ${!isBroken ? iconClasses : "fill-control-disabled-text"}`.trimEnd()}
						/>
					) : link.pwaIcon !== undefined && link.pwaIcon !== null ? (
						<ReactSVG
							src={link.pwaIcon}
							// preserveAspectRatio="none"
							className={`h-full w-full ${!isBroken ? "fill-logo-purple" : "fill-control-disabled-text"}`}
						/>
					) : link.icon !== null ? (
						<Image src={link.icon} alt={`Icon of ${link.text}.`} />
					) : (
						<div
							className={`h-full w-full rounded-full p-1 ${!isBroken ? "bg-logo-purple" : "bg-control-disabled-text"}`}>
							{link.type === "asset" || link.type === "url" ? (
								<DownloadIcon
									className={`h-full w-full p-1 ${!isBroken ? "fill-white" : "fill-white/75"}`}
								/>
							) : (
								<ChevronIcon
									className={`ms-0.5 h-full w-full rotate-180 p-1 ${!isBroken ? "fill-white" : "fill-white/75"}`}
								/>
							)}
						</div>
					)}
				</div>
			}
		/>
	)
}

const DualColumn = ({ block, ...props }: ComponentPropsWithRef<"div"> & { block: Links }): JSX.Element => {
	const chunks = chunk(block.links, 2) // 2 columns/4 rows

	return (
		<div {...props} className={`flex flex-col flex-wrap gap-y-3 ${props.className ?? ""}`.trimEnd()}>
			{chunks.map((_chunk, num) => (
				<div key={num} className="flex flex-row gap-x-3">
					{_chunk.map((link, index) => (
						<LinkButton
							key={index}
							block={block}
							link={link}
							className="min-h-28 w-1/2 flex-1 flex-col justify-between gap-y-2"
						/>
					))}
				</div>
			))}
		</div>
	)
}

const SingleColumn = ({ block, ...props }: ComponentPropsWithRef<"div"> & { block: Links }): JSX.Element => (
	<div {...props} className={`flex flex-col gap-y-3 ${props.className ?? ""}`.trimEnd()}>
		{block.links.map((link, index) => (
			<LinkButton key={index} block={block} link={link} className="flex-row items-center gap-x-4" />
		))}
	</div>
)

/**
 * A content block that renders links.
 * @returns A React component.
 * @example <LinksContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const LinksContentBlock = ({ block, ...props }: ComponentPropsWithRef<"div"> & { block: Links }): JSX.Element =>
	block.columnLayout === true ? <DualColumn {...props} block={block} /> : <SingleColumn {...props} block={block} />

export default LinksContentBlock
