import { type ContentBlock, type ContentBlocks, ContentBlockTypes } from "~/types/api/common/content-block"

/**
 * A content block that contains a single image.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Image extends ContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.ImageBlock

	/**
	 * The absolute URL of the image file.
	 * @example https://paediatrics.handi-app.co.uk/assets/images/bath/HANDi-QR-codes.jpg
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	image: string | null

	/**
	 * The screen reader text describing the image.
	 * @example "HANDi QR codes"
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	imageTitle: string | null

	/**
	 * The width of the image as a percentage of the container.
	 * @example "50" // 50% width
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	imageWidth: "50" | "66" | "75" | "100"
}

/**
 * Ensures the given content block is an image block.
 * @param block Any content block.
 * @returns Whether the content block is an image block.
 * @example if (isImageContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isImageContentBlock = (block: ContentBlocks): block is Image => block.type === ContentBlockTypes.ImageBlock
