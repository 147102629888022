import { ContentBlockTypes, type ContentBlock, type ContentBlocks } from "~/types/api/common/content-block"

/**
 * An icon with associated text.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Icon {
	/**
	 * The absolute URL of the icon file.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	image: string | null

	/**
	 * The text to display with the icon.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	text: string // Pretty
}

/**
 * A content block that contains multiple icons.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Icons extends ContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.IconList

	/**
	 * The icons to display.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	icons: Icon[]
}

/**
 * Ensures the given content block is an icons block.
 * @param block Any content block.
 * @returns Whether the content block is an icons block.
 * @example if (isIconsContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isIconsContentBlock = (block: ContentBlocks): block is Icons => block.type === ContentBlockTypes.IconList
