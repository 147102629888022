import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

/**
 * The navigation breadcrumbs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.6
 */
export interface Breadcrumbs {
	/**
	 * The ID of the current entry.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.7
	 */
	currentEntryId: number | null

	/**
	 * The index of the furthest dropdown in the breadcrumbs.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.7
	 */
	furthestDropDownIndex: number
}

/**
 * A Redux slice for storing breadcrumbs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.6
 */
export const slice = createSlice({
	name: "breadcrumbs",
	initialState: {
		currentEntryId: null,
		furthestDropDownIndex: 0 // Only show the first
	} satisfies Breadcrumbs as Breadcrumbs,
	reducers: {
		setCurrentEntryId: (state, action: PayloadAction<number | null>): Breadcrumbs => ({
			...state,
			currentEntryId: action.payload
		}),

		setFurthestDropDownIndex: (state, action: PayloadAction<number>): Breadcrumbs => ({
			...state,
			furthestDropDownIndex: action.payload
		})
	}
})

/**
 * Sets the ID of the current Craft CMS entry.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.7
 */
export const { setCurrentEntryId } = slice.actions

/**
 * Sets the index of the furthest dropdown in the breadcrumbs.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.7
 */
export const { setFurthestDropDownIndex } = slice.actions

/**
 * The slice's reducer for the breadcrumbs.
 * This should be added to the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.6
 */
export default slice.reducer
