import type { ComponentPropsWithRef, JSX } from "react"

import Image from "~/components/standard/image"
import type { Image as _Image } from "~/types/api/content-blocks/image"

/**
 * A content block that renders a single image.
 * @returns A React component.
 * @example <ImageContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ImageContentBlock = ({ block, ...props }: ComponentPropsWithRef<"img"> & { block: _Image }): JSX.Element => {
	if (block.image === null) return <></>

	if (block.imageWidth === "50")
		return (
			<div className="flex flex-row gap-x-4" data-requires-merge={true}>
				<Image
					{...props}
					src={block.image}
					alt={block.imageTitle ?? "This image has no accessibility description."}
					style={{
						width: `${block.imageWidth.toString()}%`
					}}
				/>
			</div>
		)

	return (
		<Image
			{...props}
			src={block.image}
			alt={block.imageTitle ?? "This image has no accessibility description."}
			className="m-auto"
			style={{
				width: `${block.imageWidth.toString()}%`
			}}
		/>
	)
}

export default ImageContentBlock
