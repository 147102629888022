import { ContentBlockTypes, type ContentBlock, type ContentBlocks } from "~/types/api/common/content-block"

/**
 * Represents a single image with custom display text.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Image {
	/**
	 * The display text for the image.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	heading: string

	/**
	 * The screen reader text describing the image.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	text: string

	/**
	 * The absolute URL of the image file.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	image: string
}

/**
 * A content block that contains multiple images.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Images extends ContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.PhotoList

	/**
	 * The images to display.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	photos: Image[]
}

/**
 * Ensures the given content block is an images block.
 * @param block Any content block.
 * @returns Whether the content block is an images block.
 * @example if (isImagesContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isImagesContentBlock = (block: ContentBlocks): block is Images =>
	block.type === ContentBlockTypes.PhotoList
