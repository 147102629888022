import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useCraftSites } from "~/hooks/use-craft-sites"
import { useParameters } from "~/hooks/use-parameters"
import { useRoleSelection, useSelectionsDispatch, useSiteSelection } from "~/hooks/use-selections"
import { generateSelectRoleRoute, Routes } from "~/router/routes"
import { isAnyRole } from "~/types/api/roles"

/**
 * Validates the selections in Redux state against the URL parameters for the current route.
 * If the selections don't match the URL parameters, they are updated so long as they're valid. If they aren't, state is reset and the user is redirected to the appropriate page.
 * @returns Whether the Redux state selections and URL parameters are mismatching. This should be used to prevent further child page (outlet) rendering until the site selection is correct, otherwise child pages may make requests to the API with an invalid site handle.
 * @example const { isSiteMismatch, isRoleMismatch } = useStateParameterValidation()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useStateParameterValidation = (): {
	isSiteMismatch: boolean
	isRoleMismatch: boolean

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const siteHandle = useSiteSelection()
	const roleHandle = useRoleSelection()

	const { updateSite, updateRole } = useSelectionsDispatch()

	const navigate = useNavigate()
	const { siteParameter, roleParameter } = useParameters()

	const { sites, isReady, isSuccess, isError, error } = useCraftSites({ skip: siteHandle === siteParameter })

	// Updates the site selection in global state to match the URL parameter...
	useEffect(() => {
		if (siteParameter === null) return // Don't continue if the parameter isn't set, not all routes have a site parameter
		if (siteHandle === siteParameter) return // Don't continue if state already matches the URL
		if (sites === null) return // Don't continue if we don't have the required data yet

		// Force the user to select a trust if the URL parameter doesn't match any of the available sites...
		const isSiteValid = sites.some(({ handle }) => handle === siteParameter)
		if (!isSiteValid) {
			console.warn(`Unknown site handle '${siteParameter}' from URL parameters!`)

			updateSite(null)
			navigate(Routes.SelectTrust)

			return
		}

		updateSite(siteParameter)
	}, [updateSite, navigate, siteHandle, siteParameter, sites])

	// Updates the role selection in global state to match the URL parameter...
	useEffect(() => {
		if (roleParameter === null) return // Don't continue if the parameter isn't set, not all routes have a role parameter
		if (roleHandle === roleParameter) return // Don't continue if state already matches the URL
		if (siteHandle === null) return // Don't continue if we don't have the required state yet

		if (!isAnyRole(roleParameter)) {
			console.warn(`Invalid role '${roleParameter}' from URL parameters!`)

			updateRole(null)
			navigate(generateSelectRoleRoute(siteHandle))

			return
		}

		updateRole(roleParameter)
	}, [navigate, updateRole, roleHandle, roleParameter, siteHandle])

	return {
		isSiteMismatch: siteHandle !== siteParameter,
		isRoleMismatch: roleHandle !== roleParameter,

		isReady,
		isSuccess,
		isError,

		error
	}
}
