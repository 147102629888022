import { useEffect, useReducer, type AnyActionArg } from "react"

/**
 * The results of each media query.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface MediaQueryResults {
	/**
	 * Whether the viewport is considered to be a mobile device (maximum width of 430px).
	 * @example const { isMobile } = useMediaQueries()
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	isMobile: boolean | null

	/**
	 * Whether the viewport is in landscape orientation.
	 * @example const { isLandscape } = useMediaQueries()
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	isLandscape: boolean | null

	/**
	 * Whether the viewport is in portrait orientation.
	 * @example const { isPortrait } = useMediaQueries()
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	isPortrait: boolean | null

	/**
	 * Whether the viewport is considered to be a medium width (minimum width of 420px).
	 * @example const { isMediumWidth } = useMediaQueries()
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	isMediumWidth: boolean | null
}

/**
 * A React hook to execute various media queries.
 * @returns The results of the media queries.
 * @example const { isMobile } = useMediaQueries()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useMediaQueries = (): MediaQueryResults => {
	const [results, updateResults] = useReducer<MediaQueryResults, AnyActionArg>(
		() => ({
			isMobile: window.matchMedia("(max-width: 430px)").matches,

			isLandscape:
				window.matchMedia("(orientation: landscape)").matches &&
				window.matchMedia("(max-height: 640px)").matches,
			isPortrait: window.matchMedia("(orientation: portrait)").matches,

			isMediumWidth: window.matchMedia("(min-width: 420px)").matches
		}),
		{
			isMobile: null,

			isLandscape: null,
			isPortrait: null,

			isMediumWidth: null
		}
	)

	useEffect(() => {
		updateResults()

		// Update results when the viewport is resized...
		window.addEventListener("resize", updateResults)
		return () => {
			window.removeEventListener("resize", updateResults)
		}
	}, [updateResults])

	return results
}
