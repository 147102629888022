import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useFetchEntriesQuery } from "~/api/client"
import { orNull } from "~/helpers/primitives"
import type { PartialEntry } from "~/types/api/routes/partial-entries"

/**
 * Fetches all partial Craft CMS entries for a site (NHS trust).
 * @param params The parameters.
 * @param params.siteHandle The handle of the site to fetch entries for.
 * @param params.filter A function to filter entries by. This populates the partialEntry result, but does NOT change the partialEntries result.
 * @param params.skip Whether to skip fetching entries.
 * @returns The partial entries & API request status.
 * @example const { partialEntries } = usePartialCraftEntries()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useCraftEntries = ({
	siteHandle,
	filter,
	skip = false
}: {
	siteHandle: string | null
	filter?: (partialEntry: PartialEntry) => boolean
	skip?: boolean
}): {
	partialEntries: PartialEntry[] | null
	partialEntry: PartialEntry | null
	isReady: boolean
	isSuccess: boolean
	isError: boolean
	error: FetchBaseQueryError | SerializedError | null
} => {
	const { currentData, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useFetchEntriesQuery(
		{
			siteHandle: siteHandle ?? "you-should-never-see-this"
		},
		{
			skip: skip || orNull(siteHandle) === null
		}
	)

	const partialEntry = useMemo<PartialEntry | null>(
		() => (filter !== undefined ? (currentData?.find(filter) ?? null) : null),
		[currentData, filter]
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	return {
		partialEntries: currentData ?? null,
		partialEntry,
		isReady,
		isSuccess,
		isError,
		error: isError ? error : null
	}
}
