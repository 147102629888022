import type { ComponentPropsWithRef, JSX } from "react"

import type { Icon as _Icon, Icons } from "~/types/api/content-blocks/icons"

const Icon = ({
	icon,

	...props
}: ComponentPropsWithRef<"div"> & { icon: _Icon }): JSX.Element => (
	<div {...props} className={`flex flex-row items-center gap-x-3 ${props.className ?? ""}`.trimEnd()}>
		{icon.image !== null ? (
			<img
				src={icon.image}
				alt={`Icon of ${icon.text}.`}
				width={48}
				height={48}
				className="aspect-square overflow-visible rounded-full bg-purple-alt/15 object-contain p-2"
			/>
		) : (
			<div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-alt/15 text-2xl">?</div>
		)}

		<p>{icon.text}</p>
	</div>
)

/**
 * A content block that renders icons.
 * @returns A React component.
 * @example <IconsContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const IconsContentBlock = ({ block, ...props }: ComponentPropsWithRef<"div"> & { block: Icons }): JSX.Element => (
	<div {...props} className={`flex flex-col gap-y-3 ${props.className ?? ""}`.trimEnd()}>
		{block.icons.map((icon, index) => (
			<Icon key={index} icon={icon} />
		))}
	</div>
)

export default IconsContentBlock
