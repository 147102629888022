import type { JSX } from "react"
import { Navigate } from "react-router-dom"

import { useSiteSelection } from "~/hooks/use-selections"
import { generateSelectRoleRoute, Routes } from "~/router/routes"

/**
 * A page that redirects to the select NHS trust page or select role page depending on state.
 * @returns The React component. This should only be used by the router.
 * @example <IndexPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const IndexPage = (): JSX.Element => {
	const siteHandle = useSiteSelection()
	return <Navigate to={siteHandle === null ? Routes.SelectTrust : generateSelectRoleRoute(siteHandle)} />
}

export default IndexPage
