import { useCallback } from "react"
import { updateFirebaseAnalyticsDefaultEventParameters } from "~/helpers/analytics"

import { LocalStorageKeys, type Selections, updateRole, updateSite } from "~/state/slices/selections"
import { useReduxDispatch, useReduxSelector } from "~/state/store"

type UpdateSiteCallback = (site: Selections["site"]) => void
type UpdateRoleCallback = (role: Selections["role"]) => void

/**
 * A React hook to update selections in the Redux store.
 * @returns Functions to update the site & role selections.
 * @example const { setSite, setRole } = useSelectionsDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useSelectionsDispatch = (): {
	updateSite: UpdateSiteCallback
	updateRole: UpdateRoleCallback
} => {
	const dispatch = useReduxDispatch()

	return {
		updateSite: useCallback<UpdateSiteCallback>(
			site => {
				if (site !== null) window.localStorage.setItem(LocalStorageKeys.Site.valueOf(), site)
				else window.localStorage.removeItem(LocalStorageKeys.Site.valueOf())

				updateFirebaseAnalyticsDefaultEventParameters({
					trust: site
				})

				dispatch(updateSite(site))
			},
			[dispatch]
		),
		updateRole: useCallback<UpdateRoleCallback>(
			role => {
				if (role !== null) window.localStorage.setItem(LocalStorageKeys.Role.valueOf(), role.toString())
				else window.localStorage.removeItem(LocalStorageKeys.Role.valueOf())

				// Don't bother if this is for the about pages, just retain whatever we had before
				if (role !== "about")
					updateFirebaseAnalyticsDefaultEventParameters({
						role
					})

				dispatch(updateRole(role))
			},
			[dispatch]
		)
	}
}

/**
 * A React hook to retrieve the site selection from the Redux store.
 * @returns The handle of the current site selection, or null if not selected yet.
 * @example const siteHandle = useSiteSelection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useSiteSelection = (): Selections["site"] => useReduxSelector(store => store.selections.site)

/**
 * A React hook to retrieve the role selection from the Redux store.
 * @returns The current role selection, or null if not selected yet
 * @example const role = useRoleSelection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useRoleSelection = (): Selections["role"] => useReduxSelector(store => store.selections.role)
