import { useMemo } from "react"

import type { PartialEntry } from "~/types/api/routes/partial-entries"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
if (API_BASE_URL === undefined || API_BASE_URL === "") throw new Error("The API base URL is missing!")

/**
 * Generates the URL to the Craft CMS admin page for the given entry.
 * @param params The parameters.
 * @param params.partialEntry The partial Craft CMS entry to generate the URL for.
 * @param params.siteHandle The current site (NHS trust) handle.
 * @param params.roleHandle The current role (section) handle.
 * @returns The URL to the Craft CMS admin page.
 * @example const { url } = useCraftAdmin({ partialEntry, siteHandle, roleHandle })
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.7
 */
export const useCraftAdmin = ({
	partialEntry,

	siteHandle,
	roleHandle
}: {
	partialEntry: PartialEntry | null

	siteHandle: string | null
	roleHandle: string | null
}): {
	adminUrl: string | null
} => {
	const adminUrl = useMemo<string | null>(() => {
		if (partialEntry === null) return null
		if (siteHandle === null || roleHandle === null) return null

		return `${API_BASE_URL}/admin/entries/${roleHandle}/${partialEntry.entry_id.toString()}-${partialEntry.slug}?site=${siteHandle}` // https://cms.handi.local/admin/entries/professionals/9667-viral-wheeze-in-primary-care?site=bath
	}, [partialEntry, siteHandle, roleHandle])

	return {
		adminUrl
	}
}
