import { useCallback, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import Button from "~/components/standard/button"
import { useCraftEntries } from "~/hooks/use-craft-entries"
import { useRoleSelection, useSiteSelection } from "~/hooks/use-selections"
import { generateCraftEntryRoute } from "~/router/routes"
import type { Choice } from "~/types/api/content-blocks/choice"
import type { CraftEntryState } from "~/types/state"

const ChoiceButton = ({
	label,
	entryId,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "onClick"> & { label: string; entryId?: number }): JSX.Element => {
	const navigate = useNavigate()

	const siteHandle = useSiteSelection()
	const roleHandle = useRoleSelection()

	const { partialEntry } = useCraftEntries({
		siteHandle,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		filter: ({ section, entry_id }) => section === roleHandle && entry_id === (entryId ?? 0),
		skip: entryId === undefined || roleHandle === null
	})

	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (entryId === undefined) {
			console.warn("Choice button does not point to a Craft CMS entry ID!")
			return
		}

		if (partialEntry === null) {
			console.warn(`Choice button could not find partial Craft CMS entry ID ${entryId.toString()}!`)
			return
		}

		if (siteHandle === null) {
			console.warn("No site selection yet?!")
			return
		}

		if (roleHandle === null) {
			console.warn("No role selection yet?!")
			return
		}

		navigate(generateCraftEntryRoute(siteHandle, roleHandle, partialEntry.slug), {
			state: {
				partialEntry
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, partialEntry, siteHandle, roleHandle, entryId])

	return (
		<Button
			{...props}
			label={label}
			onClick={onClick}
			className={`text-logo min-h-12 flex-1 items-center justify-center rounded-2xl p-2 font-bold duration-hover ${props.disabled === true ? "!hover:bg-control-hover !active:bg-control-active pointer-events-none cursor-not-allowed !bg-control-disabled-background !text-control-disabled-text" : ""} ${props.className ?? ""}`.trimEnd()}
		/>
	)
}

/**
 * Two buttons for making a choice.
 * Not guaranteed to always say 'Yes' and 'No'.
 * @returns A React component.
 * @example <ChoiceContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ChoiceContentBlock = ({ block, ...props }: ComponentPropsWithRef<"div"> & { block: Choice }): JSX.Element => (
	<div {...props} className={`flex flex-row justify-between gap-x-4 ${props.className ?? ""}`.trimEnd()}>
		<ChoiceButton
			label="Yes" // {block.yesLink.text}
			entryId={block.yesLink.theLink.value !== null ? parseInt(block.yesLink.theLink.value, 10) : undefined}
			disabled={block.yesLink.theLink.value === null}
			className="bg-logo-purple text-white hover:bg-purple-alt active:bg-purple-alt/95"
		/>
		<ChoiceButton
			label="No" // {block.noLink.text}
			entryId={block.noLink.theLink.value !== null ? parseInt(block.noLink.theLink.value, 10) : undefined}
			disabled={block.yesLink.theLink.value === null}
			className="bg-purple-alt/15 text-logo-purple hover:bg-purple-alt/25 active:bg-purple-alt/35"
		/>
	</div>
)

export default ChoiceContentBlock
