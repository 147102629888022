import { type HTMLMotionProps, motion } from "framer-motion"
import type { JSX } from "react"
import { Navigate } from "react-router-dom"

import BackgroundImage from "~/components/background-image"
import ContentBlocks from "~/components/content-blocks/content-blocks"
import ContentCard from "~/components/content-card"
import Metadata from "~/components/metadata"
import QuickNavigation from "~/components/quick-navigation"
import Image from "~/components/standard/image"
import { BackgroundImagePath } from "~/images"
import { Routes } from "~/router/routes"
import { ContentBlockTypes } from "~/types/api/common/content-block"
import type { Text } from "~/types/api/content-blocks/text"

const DemoEntryPage = ({ ...props }: HTMLMotionProps<"main">): JSX.Element => {
	if (!import.meta.env.DEV) return <Navigate to={Routes.Index} />

	return (
		<motion.main
			{...props}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={`flex flex-grow flex-col justify-end overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
			<Metadata title="Demo Entry" />

			{/* Feature */}
			<BackgroundImage
				imageUrl={BackgroundImagePath.ChildWithSymptoms}
				accessibilityDescription="A parent checking on a child that has symptoms."
			/>

			{/* Card */}
			<ContentCard heading="Demo Entry">
				<ContentBlocks
					blocks={[
						{
							id: 0,
							type: ContentBlockTypes.Text,
							text: '<h1>Heading 1</h1><h2>Heading 2</h2><h3>Heading 3</h3><p>Paragraph</p><h4>Heading 4</h4><p>A <a href="#">link</a>.</p><h5>Heading 5</h5><ul><li>Item 1</li><li>Item 2</li><li>Item 3</li></ul><h5>Heading 6</h5><ol><li>Item 1</li><li>Item 2</li><li>Item 3</li></ol>',
							style: "default"
						} satisfies Text as Text
					]}
				/>

				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-2">
						<Image
							src={BackgroundImagePath.HandPaintingChild}
							alt="A child painting with their hands."
							style={{
								width: "50%"
							}}
						/>
						<Image
							src={BackgroundImagePath.HandPaintingChild}
							alt="A child painting with their hands."
							style={{
								width: "50%"
							}}
						/>
					</div>
					<p>
						Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque odio ea amet excepturi ut ipsum
						vitae iusto voluptatibus sit, reprehenderit nulla, voluptatem ipsam vero tenetur laboriosam
						obcaecati praesentium itaque quisquam.
					</p>
				</div>

				<QuickNavigation />
			</ContentCard>
		</motion.main>
	)
}

export default DemoEntryPage
