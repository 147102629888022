import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"

/**
 * Ensures an RTK query error is a base query error.
 * @param error The RTK query error.
 * @returns Whether it is a base query error.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.0.1
 */
export const isBaseQueryError = (
	error: Error | FetchBaseQueryError | SerializedError | null | undefined
): error is FetchBaseQueryError => error !== null && error !== undefined && "status" in error && "data" in error

/**
 * Ensures an RTK query error is a serialized query error.
 * @param error The RTK query error.
 * @returns Whether it is a serialized query error.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.0.1
 */
export const isSerializedError = (
	error: Error | FetchBaseQueryError | SerializedError | null | undefined
): error is SerializedError =>
	error !== null && error !== undefined && "name" in error && "message" in error && "code" in error
