import { ContentBlockTypes, type ContentBlocks, type StyledContentBlock } from "~/types/api/common/content-block"
import type { TheLink } from "~/types/api/common/link"

/**
 * A custom link with an icon & display text.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Link {
	/**
	 * The type of link.
	 * 'entry' is a link to a Craft entry.
	 * 'tel' is a telephone number (e.g., 111, 999).
	 * 'asset' is a link to a file (e.g., a PDF).
	 * 'url' is a link to an external website.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: "asset" | "entry" | "tel" | "url"

	/**
	 * The display text for the link.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	text: string

	/**
	 * The absolute URL of the icon for the link.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	icon: string | null

	/**
	 * The absolute URL to the link's target.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	url: string | null

	/**
	 * An inner link object with its own type, display text & accessibility attributes.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	theLink: TheLink

	/**
	 * The absolute URL of the modern icon.
	 * This was not part of the original API!
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.7
	 */
	pwaIcon?: string | null
}

/**
 * A content block that contains multiple links.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Links extends StyledContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.Links

	/**
	 * The links to display in the block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	links: Link[]

	/**
	 * Whether these links should render as dual columns.
	 * This was not part of the original API!
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.7
	 */
	columnLayout?: boolean
}

/**
 * Ensures the given content block is a links block.
 * @param block Any content block.
 * @returns Whether the content block is a links block.
 * @example if (isLinksContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isLinksContentBlock = (block: ContentBlocks): block is Links => block.type === ContentBlockTypes.Links
