import { ContentBlockTypes, type ContentBlock, type ContentBlocks } from "~/types/api/common/content-block"
import type { TheLink } from "~/types/api/common/link"

/**
 * A content block that contains a positive/negative choice with links.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Choice extends ContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.YesNoLinks

	/**
	 * The question to ask the user.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	question: string | null

	/**
	 * The positive choice.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	yesLink: {
		/**
		 * The type of link.
		 * 'entry' is a link to a Craft entry.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		type: "entry"

		/**
		 * The display text for the link.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		text: string

		/**
		 * An inner link object with its own type, display text & accessibility attributes.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		theLink: TheLink
	}

	/**
	 * The negative choice.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	noLink: {
		/**
		 * The type of link.
		 * 'entry' is a link to a Craft entry.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		type: "entry"

		/**
		 * The display text for the link.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		text: string

		/**
		 * An inner link object with its own type, display text & accessibility attributes.
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		theLink: TheLink
	}
}

/**
 * Ensures the given content block is a choice block.
 * @param block Any content block.
 * @returns Whether the content block is a choice block.
 * @example if (isChoiceContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isChoiceContentBlock = (block: ContentBlocks): block is Choice =>
	block.type === ContentBlockTypes.YesNoLinks
