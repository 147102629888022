import type { ComponentPropsWithRef, JSX } from "react"

/**
 * A skeleton loader that pulses while loading, then fades in the content when loaded.
 * @param params The component parameters.
 * @param params.isLoading Whether the pulsing animation should show.
 * @param params.rowCount The number of fake rows to show.
 * @param params.rowClassName The classes for the fake rows.
 * @param params.innerClassName The classes for the inner <div />.
 * @param params.children The content to render when not loading.
 * @returns The React component.
 * @example <SkeletonLoader isLoading={true} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const SkeletonLoader = ({
	isLoading,

	rowCount,
	rowClassName,

	innerClassName,

	children,
	...props
}: ComponentPropsWithRef<"div"> & {
	isLoading: boolean

	rowCount?: number
	rowClassName?: string

	innerClassName?: string
}): JSX.Element => (
	<div
		{...props}
		className={`flex flex-grow flex-col ${isLoading ? `animate-pulse cursor-wait ${rowCount === undefined ? "rounded-2xl bg-control-background" : "bg-inherit"}` : "bg-inherit"} ${props.className ?? ""}`.trimEnd()}>
		{/* Fake rows */}
		{rowCount !== undefined && isLoading && (
			<div className="flex flex-grow flex-col gap-y-4 overflow-y-hidden">
				{Array.from({ length: rowCount }).map((_, index) => (
					<div
						key={index}
						className={`flex min-h-12 w-full flex-row rounded-2xl bg-control-background ${rowClassName ?? ""}`.trimEnd()}
					/>
				))}
			</div>
		)}

		{/* Content */}
		<div
			// flex-col
			className={`flex flex-grow duration-1000 ${isLoading ? "opacity-0" : "opacity-100"} ${innerClassName ?? ""}`.trimEnd()}>
			{children}
		</div>
	</div>
)

export default SkeletonLoader
