import { useCallback, useEffect, useReducer, type AnyActionArg, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import { Routes } from "~/router/routes"

/**
 * A React hook for navigating to the previous route, or home if there is no previous route.
 * @returns A function to navigate back, and a boolean indicating if there is any history.
 * @example const { onClick, hasHistory } = useNavigateBack()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useNavigateBack = <Type extends Element>(): {
	onClick: MouseEventHandler<Type>
	hasHistory: boolean
} => {
	const navigate = useNavigate()

	// Assume we can go back by default, we don't want to break the button...
	const [hasHistory, checkHistoryLength] = useReducer<boolean, AnyActionArg>(() => window.history.length > 2, false)

	// Navigate back when the back button is clicked...
	const onClick = useCallback<MouseEventHandler<Type>>(
		event => {
			event.preventDefault()

			// For some reason this doesn't work as a ternary?
			if (hasHistory) navigate(-1)
			else navigate(Routes.Index)
		},
		[navigate, hasHistory]
	)

	// Enable/disable the back button whenever history changes...
	useEffect(() => {
		checkHistoryLength()

		window.addEventListener("popstate", checkHistoryLength)

		return () => {
			window.removeEventListener("popstate", checkHistoryLength)
		}
	}, [])

	return {
		onClick,
		hasHistory
	}
}
