/**
 * The possible roles for a user to select.
 * These should match the appropriate Craft CMS section handles.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export type AnyRole = "about" | "hospital" | "parents" | "professionals" // About isn't really a role

/**
 * Ensures the value is at least one of the possible roles.
 * @param value The value to check.
 * @returns Whether the value is a role.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isAnyRole = (value: unknown): value is AnyRole =>
	typeof value === "string" && ["hospital", "parents", "professionals", "about"].includes(value)
