import type { ComponentPropsWithRef, JSX } from "react"

/**
 * A standard HTML button element.
 * @param params The component parameters. The <button> type prop is fixed.
 * @param params.label The label on the button.
 * @param params.icon The icon on the button.
 * @returns The React component.
 * @example <Button label="Hello World" />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Button = ({
	label,
	icon,

	...props
}: Omit<ComponentPropsWithRef<"button">, "type"> & {
	label: string
	icon?: JSX.Element | ((isDisabled: boolean) => JSX.Element)
}): JSX.Element => (
	<button
		{...props}
		type="button"
		className={`flex flex-row ${props.disabled === true ? "cursor-not-allowed fill-control-disabled-text text-control-disabled-text" : "fill-logo-purple text-logo-purple"} ${props.className ?? ""}`.trimEnd()}>
		{typeof icon === "function" ? icon(props.disabled ?? false) : icon}
		{label}
	</button>
)

export default Button
