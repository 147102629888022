import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

/**
 * Information about the layout of the app.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
export interface Layout {
	/**
	 * Whether the search bar is visible in the header.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.9
	 */
	isSearchVisible: boolean

	/**
	 * Whether the breadcrumbs is visible in the header.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.9
	 */
	isBreadcrumbsVisible: boolean

	/**
	 * The vertical offset of the content card.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.9
	 */
	contentCardVerticalOffset: number | null
}

/**
 * A Redux slice for storing layout information.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
export const slice = createSlice({
	name: "layout",
	initialState: {
		isSearchVisible: false,
		isBreadcrumbsVisible: false,
		contentCardVerticalOffset: null
	} satisfies Layout as Layout,
	reducers: {
		setSearchVisibility: (state, action: PayloadAction<boolean>): Layout => ({
			...state,
			isSearchVisible: action.payload
		}),

		setBreadcrumbsVisibility: (state, action: PayloadAction<boolean>): Layout => ({
			...state,
			isBreadcrumbsVisible: action.payload
		}),

		setContentCardVerticalOffset: (state, action: PayloadAction<number | null>): Layout => ({
			...state,
			contentCardVerticalOffset: action.payload
		})
	}
})

/**
 * Sets whether the search bar is visible in the header.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
export const { setSearchVisibility } = slice.actions

/**
 * Sets whether the breadcrumbs is visible in the header.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
export const { setBreadcrumbsVisibility } = slice.actions

/**
 * Sets the vertical offset of the content card.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
export const { setContentCardVerticalOffset } = slice.actions

/**
 * The reducer for the layout information slice.
 * This should be added to the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
export default slice.reducer
