import type { AnyRole } from "~/types/api/roles"

/**
 * The routes handled by the client-side router.
 * These mimic the routes in the former HANDi Paediatrics app.
 * @example <Link to={Routes.Index}>Home</Link>
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export enum Routes {
	Index = "/",

	SelectTrust = "/select",
	SelectRole = "/:site/home",
	CraftEntry = "/:site/:role/:slug",

	Settings = "/settings"
}

/**
 * Generates the route for selecting a role.
 * @param siteHandle The handle of the site (NHS trust).
 * @returns The route.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const generateSelectRoleRoute = (siteHandle: string): string => `/${siteHandle}/home`

/**
 * Generates the route for a Craft CMS entry.
 * @param siteHandle The handle of the site (NHS trust).
 * @param role The selected role.
 * @param slug The slug of the Craft entry.
 * @returns The route.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const generateCraftEntryRoute = (siteHandle: string, role: AnyRole, slug: string): string =>
	`/${siteHandle}/${role}/${slug}`
