import {
	createApi,
	fetchBaseQuery,
	retry,
	type BaseQueryFn,
	type FetchArgs,
	type FetchBaseQueryError,
	type FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react"
import { captureEvent } from "@sentry/react"

import { REACT_VERSION } from "~/constants"
import { deepTransformObjectProperties } from "~/helpers/primitives"
import type { Entry } from "~/types/api/routes/entry"
import type { PartialEntries, PartialEntry } from "~/types/api/routes/partial-entries"
import type { Site, Sites } from "~/types/api/routes/sites"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
if (API_BASE_URL === undefined || API_BASE_URL === "") throw new Error("The API base URL is missing!")

const baseQuery = fetchBaseQuery({
	baseUrl: API_BASE_URL,
	timeout: 5000, // 5 seconds
	prepareHeaders: (headers): void => {
		// Content
		headers.set("Accept", "application/json")

		// Identification
		headers.set(
			"User-Agent",
			`HANDi Paediatrics/${VITE_BITBUCKET_TAG ?? "0.0.0"} (technical@yello.studio; https://yello.studio)`
		)
		headers.set("From", "technical@yello.studio")

		// Technologies
		headers.set("X-Powered-By", `React/${REACT_VERSION ?? "19"} (https://react.dev)`)
		headers.set("X-Requested-With", "Redux Toolkit (https://redux-toolkit.js.org)")
	}
})

const retryingBaseQuery = retry(
	async (args: FetchArgs | string, api, extraOptions) => {
		const result = await baseQuery(args, api, extraOptions)
		const status = result.error?.status

		// Never retry for parsing errors
		if (status === "PARSING_ERROR") {
			console.warn(`Query '${api.endpoint}' failed due to malformed response!`)
			retry.fail(result.error)
		}

		// Retry for network issues
		if (status === "TIMEOUT_ERROR") {
			console.warn(`Query '${api.endpoint}' timed out, retrying...`)
			return result
		}
		if (status === "FETCH_ERROR") {
			console.warn(`Query '${api.endpoint}' failed due to a network error, retrying...`)
			return result
		}

		// Retry for server-side errors (e.g., 500, 502, 504)
		if (typeof status === "number" && status >= 500 && status <= 599) {
			console.warn(`Query '${api.endpoint}' returned HTTP status code ${status.toString()}, retrying...`)
			return result
		}

		// Never retry for client-side errors (e.g., 401, 403, 409)
		if (typeof status === "number" && status >= 400 && status <= 499) {
			console.warn(`Query '${api.endpoint}' returned HTTP status code ${status.toString()}!`)
			retry.fail(result.error)
		}

		// Generic catch-all, as status is not set on success
		if (status !== undefined) console.warn(`Query '${api.endpoint}' failed due to '${status.toString()}'`)
		return result
	},
	{
		maxRetries: 3
	}
)

const sentryErrorCaptureBaseQuery: BaseQueryFn<
	FetchArgs,
	unknown,
	FetchBaseQueryError,
	Record<never, never>,
	FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
	const result = await retryingBaseQuery(args, api, extraOptions)
	const status = result.error?.status

	if (status !== undefined) {
		const hasStatusCode = typeof status === "number"
		const errorMessages: Record<Exclude<NonNullable<typeof status>, number>, string> = {
			TIMEOUT_ERROR: `RTK query '${api.endpoint}' timed out!`,
			FETCH_ERROR: `RTK query '${api.endpoint}' failed due to a network error!`,
			PARSING_ERROR: `RTK query '${api.endpoint}' received a malformed response!`,
			CUSTOM_ERROR: `RTK query '${api.endpoint}' failed!`
		}

		captureEvent({
			level: "error",
			message: hasStatusCode
				? `RTK query '${api.endpoint}' received HTTP ${status.toString()}'`
				: errorMessages[status],
			extra: {
				requestMethod: args.method,
				requestPath: args.url,
				requestHeaders: args.headers,
				requestBody:
					args.body !== undefined && args.body !== null && args.body !== null
						? JSON.stringify(args.body)
						: null,
				fetchError: !hasStatusCode ? status : null,
				responseCode: hasStatusCode ? status : null,
				responseData: result.error?.data,
				responseBody: result.data,
				rtkQueryEndpoint: api.endpoint
			}
		})
	}

	return result
}

export const api = createApi({
	reducerPath: "api",
	baseQuery: sentryErrorCaptureBaseQuery, // We shouldn't have to do this, the types in RTK query are weird :? - https://github.com/reduxjs/redux-toolkit/issues/3983

	endpoints: builder => ({
		/**
		 * Fetches a list of sites (a.k.a. NHS trusts).
		 * This automatically de-duplicates and sorts the sites.
		 * @see https://bitbucket.org/yellostudio/handi-cms/src/paediatrics/templates/sites.json
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
		fetchSites: builder.query<Site[], void>({
			query: (): FetchArgs => ({
				method: "GET",
				url: "/sites.json"
			}),
			transformResponse: (response: Sites): Site[] => {
				// Ensure no duplicates
				const sites = response.data.reduce<Site[]>((accumulator, site) => {
					const isDuplicate = accumulator.some(({ handle }) => handle === site.handle)
					if (isDuplicate) return accumulator

					return [...accumulator, site]
				}, [])

				// Sort by end-user facing value
				sites.sort(({ name: a }, { name: b }): number => a.localeCompare(b))

				return sites
			}
		}),

		/**
		 * Fetches a list of partial Craft CMS entries (pages) for a site (a.k.a. NHS trust).
		 * @param {string} siteHandle The handle of the site (e.g., "bath").
		 * @see https://bitbucket.org/yellostudio/handi-cms/src/paediatrics/templates/sites.json
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		fetchEntries: builder.query<
			PartialEntry[], // | ErrorResponse
			{
				siteHandle: string
			}
		>({
			query: ({ siteHandle }): FetchArgs => ({
				method: "GET",
				url: `/api/${siteHandle}/entries.json`
			}),
			transformResponse: (response: PartialEntries): PartialEntry[] => response.data
		}),

		/**
		 * Fetches a single Craft CMS entry (page) for a site (a.k.a. NHS trust).
		 * @param {string} siteHandle The handle of the site (e.g., "bath").
		 * @see https://bitbucket.org/yellostudio/handi-cms/src/paediatrics/templates/sites.json
		 * @author Jay Hunter <jh@yello.studio>
		 * @since 0.1.0
		 */
		fetchEntry: builder.query<
			Entry, // | ErrorResponse
			{
				siteHandle: string
				entryId: number
			}
		>({
			query: ({ siteHandle, entryId }): FetchArgs => ({
				method: "GET",
				url: `/api/${siteHandle}/entries/${entryId.toString()}.json`
			}),
			transformResponse: (response: Entry): Entry => {
				// Trim whitespace from all strings, this API returns CMS written content!
				response = deepTransformObjectProperties(
					response,
					(_, value) => typeof value === "string",
					(_, value) => (value as string).trim()
				)

				// Keep the brand name consistent
				// response = deepTransformObjectProperties(
				// 	response,
				// 	(_, value) => typeof value === "string" && value.toLowerCase().includes("handi paediatric"),
				// 	(_, value) => (value as string).replace(/handi paediatric/gi, "HANDi Paediatrics")
				// )
				// response = deepTransformObjectProperties(
				// 	response,
				// 	(_, value) => typeof value === "string" && value.toLowerCase().includes("handi"),
				// 	(_, value) => (value as string).replace(/handi[^@-]/gi, "HANDi")
				// )

				return response
			}
		})
	})
})

/**
 * Fetches a list of sites (a.k.a. NHS trusts).
 * The sites are automatically de-duplicated and sorted.
 * @returns The query result.
 * @example const { data: sites } = useFetchSitesQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useFetchSitesQuery: typeof api.endpoints.fetchSites.useQuery = api.endpoints.fetchSites.useQuery

/**
 * Lazily fetches a list of sites (a.k.a. NHS trusts).
 * The sites are automatically de-duplicated and sorted.
 * @returns The method to trigger the query.
 * @example const [fetchSites] = useLazyFetchSitesQuery()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useLazyFetchSitesQuery: typeof api.endpoints.fetchSites.useLazyQuery =
	api.endpoints.fetchSites.useLazyQuery

/**
 * Fetches a list of partial Craft CMS entries (pages) for a site (a.k.a. NHS trusts).
 * @returns The query result.
 * @example const { data: entries } = useFetchEntriesQuery({ siteHandle: "bath" })
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useFetchEntriesQuery: typeof api.endpoints.fetchEntries.useQuery = api.endpoints.fetchEntries.useQuery

/**
 * Lazily fetches a list of partial Craft CMS entries (pages) for a site (a.k.a. NHS trusts).
 * @returns The method to trigger the query.
 * @example const [fetchEntries] = useLazyFetchEntriesQuery({ siteHandle: "bath" })
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useLazyFetchEntriesQuery: typeof api.endpoints.fetchEntries.useLazyQuery =
	api.endpoints.fetchEntries.useLazyQuery

/**
 * Fetches a single Craft CMS entry (page) for a site (a.k.a. NHS trusts).
 * @returns The query result.
 * @example const { data: entry } = useFetchEntryQuery({ siteHandle: "bath", entryId: 123 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useFetchEntryQuery: typeof api.endpoints.fetchEntry.useQuery = api.endpoints.fetchEntry.useQuery

/**
 * Lazily fetches a single Craft CMS entry (page) for a site (a.k.a. NHS trusts).
 * @returns The method to trigger the query.
 * @example const [fetchEntry] = useLazyFetchEntryQuery({ siteHandle: "bath", entryId: 123 })
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useLazyFetchEntryQuery: typeof api.endpoints.fetchEntry.useLazyQuery =
	api.endpoints.fetchEntry.useLazyQuery
