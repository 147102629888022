import { useCallback, useEffect, useMemo, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"

import {
	useBreadcrumbs,
	useBreadcrumbsFurthestDropDownIndexDispatch,
	useBreadcrumbsFurthestDropDownIndexSelection
} from "~/hooks/use-breadcrumbs"
import { useRoleSelection, useSiteSelection } from "~/hooks/use-selections"
import { generateCraftEntryRoute } from "~/router/routes"
import { setBreadcrumbsVisibility } from "~/state/slices/layout"
import { useReduxDispatch, useReduxSelector } from "~/state/store"
import { PageStyles } from "~/types/api/common/page-styles"
import type { PartialEntry } from "~/types/api/routes/partial-entries"
import type { CraftEntryState } from "~/types/state"

import ChevronIcon from "~/assets/icons/chevron.svg?react"
import TickIcon from "~/assets/icons/tick.svg?react"
import WarningIcon from "~/assets/icons/warning.svg?react"

/* eslint-disable no-nested-ternary */

const getBreadcrumbIcon = ({
	partialEntry,
	currentIndex
}: {
	partialEntry: PartialEntry
	currentIndex: number
}): JSX.Element | undefined => {
	if (partialEntry.pwaIcon !== undefined && partialEntry.pwaIcon !== null)
		return (
			<ReactSVG
				src={partialEntry.pwaIcon}
				className={`w-full ${partialEntry.pageStyle === PageStyles.Red ? "fill-algorithm-red" : partialEntry.pageStyle === PageStyles.Amber ? "fill-algorithm-amber" : partialEntry.pageStyle === PageStyles.Green ? "fill-algorithm-green" : currentIndex === 0 ? "fill-white" : "fill-logo-purple"}`.trimEnd()}
			/>
		)

	if (partialEntry.pageStyle === PageStyles.Red) return <WarningIcon className="h-full w-full fill-algorithm-red" />
	if (partialEntry.pageStyle === PageStyles.Amber)
		return <WarningIcon className="h-full w-full fill-algorithm-amber" />
	if (partialEntry.pageStyle === PageStyles.Green) return <TickIcon className="h-full w-full fill-algorithm-green" />

	return undefined
}

const Breadcrumb = ({
	partialEntry,

	currentIndex,
	maximumIndex,

	...props
}: ComponentPropsWithRef<"div"> & {
	partialEntry: PartialEntry

	currentIndex: number
	maximumIndex: number
}): JSX.Element => {
	const navigate = useNavigate()

	const siteHandle = useSiteSelection()
	const roleHandle = useRoleSelection()

	const furthestDropDownIndex = useBreadcrumbsFurthestDropDownIndexSelection()
	const { setFurthestDropDownIndex } = useBreadcrumbsFurthestDropDownIndexDispatch()

	const isExpanded = useMemo<boolean>(
		() => currentIndex < furthestDropDownIndex,
		[currentIndex, furthestDropDownIndex]
	)
	const remainingBreadcrumbs = useMemo<number>(() => maximumIndex - currentIndex, [currentIndex, maximumIndex])

	const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
		if (siteHandle === null) {
			console.warn("No site selection yet?!")
			return
		}

		if (roleHandle === null) {
			console.warn("No role selection yet?!")
			return
		}

		navigate(generateCraftEntryRoute(siteHandle, roleHandle, partialEntry.slug), {
			state: {
				partialEntry
			} satisfies CraftEntryState as CraftEntryState
		})

		// Collapse all drop-downs
		setFurthestDropDownIndex(0)
	}, [setFurthestDropDownIndex, navigate, roleHandle, siteHandle, partialEntry])

	const onChevronClick = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
		setFurthestDropDownIndex(isExpanded ? currentIndex : currentIndex + 1)
	}, [setFurthestDropDownIndex, isExpanded, currentIndex])

	const breadcrumbIcon = getBreadcrumbIcon({ partialEntry, currentIndex })

	return (
		<div
			className={`flex h-12 flex-col justify-between text-sm transition-all hover:cursor-pointer ${currentIndex > furthestDropDownIndex ? "hidden" : ""} ${furthestDropDownIndex === 0 || maximumIndex === 0 ? "rounded-xl" : currentIndex === 0 ? "rounded-t-xl" : currentIndex === maximumIndex || !isExpanded ? "rounded-b-xl" : ""} ${currentIndex === 0 ? "bg-logo-purple fill-white text-white hover:bg-purple-alt active:bg-purple-alt/95" : partialEntry.pageStyle === PageStyles.Red ? "bg-algorithm-red/15 fill-algorithm-red text-algorithm-red hover:bg-algorithm-red/20 active:bg-algorithm-red/25" : partialEntry.pageStyle === PageStyles.Amber ? "bg-algorithm-amber/15 fill-algorithm-amber text-algorithm-amber hover:bg-algorithm-amber/20 active:bg-algorithm-amber/25" : partialEntry.pageStyle === PageStyles.Green ? "bg-algorithm-green/15 fill-algorithm-green text-algorithm-green hover:bg-algorithm-green/20 active:bg-algorithm-green/25" : "bg-purple-alt/15 fill-logo-purple text-logo-purple hover:bg-purple-alt/25 active:bg-purple-alt/35"} ${props.className ?? ""}`.trimEnd()}>
			{/* Separator */}
			<hr className={`mx-0 border-logo-purple/50 ${currentIndex < 2 ? "invisible" : ""}`.trimEnd()} />

			<div className="flex flex-row justify-between gap-x-2 px-2">
				<div
					onClick={onClick}
					className="flex flex-1 flex-row items-center gap-x-3 overflow-hidden text-ellipsis whitespace-nowrap px-2">
					{/* Icon */}
					{breadcrumbIcon !== undefined && (
						<div className="flex h-6 min-h-6 w-6 min-w-6 flex-col justify-center">
							<div className="flex flex-col">{breadcrumbIcon}</div>
						</div>
					)}

					{/* Title */}
					<span>
						{partialEntry.title}
						{!isExpanded && remainingBreadcrumbs > 0 && (
							<span className="opacity-75">, and {remainingBreadcrumbs.toString()} more...</span>
						)}
					</span>
				</div>

				{/* Drop-down */}
				{currentIndex < maximumIndex && (
					<div
						onClick={onChevronClick}
						className={`flex flex-row rounded-full p-2 ${currentIndex === 0 ? "hover:bg-control-hover/15" : partialEntry.pageStyle === PageStyles.Red ? "hover:bg-algorithm-red/10" : partialEntry.pageStyle === PageStyles.Amber ? "hover:bg-algorithm-amber/10" : partialEntry.pageStyle === PageStyles.Green ? "hover:bg-algorithm-green/10" : "hover:bg-purple-alt/10"}`.trimEnd()}>
						<ChevronIcon
							width={16}
							height={16}
							className={`aspect-square rounded-full transition-transform ${isExpanded ? "-rotate-90" : "rotate-180"} ${currentIndex === 0 ? "fill-white hover:fill-white/90 active:fill-white/80" : "fill-logo-purple hover:fill-purple-alt active:fill-purple-alt/95"}`}
						/>
					</div>
				)}
			</div>

			{/* Just for equal spacing */}
			<hr className="invisible mx-0" />
		</div>
	)
}

/**
 * The collapsible breadcrumbs.
 * @returns The React component.
 * @example <Breadcrumbs />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.9
 */
const Breadcrumbs = ({ ...props }: ComponentPropsWithRef<"div">): JSX.Element => {
	const siteHandle = useSiteSelection()
	const roleHandle = useRoleSelection()

	// Fetch the breadcrumbs for the current entry
	const currentEntryId = useReduxSelector(({ breadcrumbs }) => breadcrumbs.currentEntryId) // TODO: Wrapper hook for this
	const { breadcrumbs, hasBreadcrumbs } = useBreadcrumbs({
		entryId: currentEntryId,

		siteHandle,
		roleHandle
	})

	// Let other components know if we'll be rendering breadcrumbs
	const dispatch = useReduxDispatch()
	useEffect(() => {
		dispatch(setBreadcrumbsVisibility(hasBreadcrumbs))
		return () => {
			dispatch(setBreadcrumbsVisibility(hasBreadcrumbs))
		}
	}, [dispatch, hasBreadcrumbs])

	// Don't bother rendering anything if we haven't got any breadcrumbs
	if (!hasBreadcrumbs) return <></>

	return (
		<div {...props} className={`m-1 flex flex-col ${props.className ?? ""}`.trimEnd()}>
			<div className="flex flex-col">
				{breadcrumbs
					?.slice(0, 1)
					.map((partialEntry, index) => (
						<Breadcrumb
							key={partialEntry.entry_id}
							partialEntry={partialEntry}
							currentIndex={index}
							maximumIndex={breadcrumbs.length - 1}
						/>
					))}
			</div>

			<div className="fixed mt-12 flex w-[calc(100%-3.5rem)] flex-col rounded-b-xl bg-white">
				{breadcrumbs
					?.slice(1)
					.map((partialEntry, index) => (
						<Breadcrumb
							key={partialEntry.entry_id}
							partialEntry={partialEntry}
							currentIndex={index + 1}
							maximumIndex={breadcrumbs.length - 1}
						/>
					))}
			</div>
		</div>
	)
}

export default Breadcrumbs
