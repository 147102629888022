import { useState, type ComponentPropsWithRef, type JSX } from "react"
import { preload } from "react-dom"

import { useReduxSelector } from "~/state/store"

/**
 * A background image that smoothly fades in.
 * To apply a feathering effect, apply the before:h-12 & after:h-12 Tailwind CSS classes.
 * @param params The component parameters. The <div> title prop is fixed.
 * @param params.imageUrl The URL to the image.
 * @param params.accessibilityDescription The description of the image for screen readers.
 * @param params.onReady A callback function that is fired once the image has loaded.
 * @returns The React component.
 * @example <BackgroundImage imageUrl="/images/backgrounds/hand-painting-child.webp" accessibilityDescription="A child with paint on their hands." />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const BackgroundImage = ({
	imageUrl,
	accessibilityDescription,

	...props
}: Omit<ComponentPropsWithRef<"div">, "id" | "title"> & {
	imageUrl: string
	accessibilityDescription: string
}): JSX.Element => {
	const { contentCardVerticalOffset } = useReduxSelector(({ layout }) => layout)

	const [isCached] = useState<boolean>(true)

	// https://react.dev/blog/2024/04/25/react-19#support-for-preloading-resources
	preload(imageUrl, {
		as: "image",
		referrerPolicy: "no-referrer"
		//crossOrigin: "anonymous"
	})

	return (
		<div
			{...props}
			title={accessibilityDescription}
			className={`absolute top-4 -z-10 w-full bg-cover bg-center bg-no-repeat transition-all duration-image before:absolute before:left-0 before:right-0 before:top-0 before:h-12 before:bg-gradient-to-b before:from-logo-purple before:to-transparent after:absolute after:bottom-0 after:left-0 after:right-0 after:h-12 after:bg-gradient-to-t after:from-logo-purple after:to-transparent ${isCached ? "opacity-100" : "animate-pulse cursor-wait opacity-0"} ${props.className ?? ""}`.trimEnd()}
			style={{
				backgroundImage: `url(${imageUrl})`,

				// top: `calc(1rem + ${isBreadcrumbsVisible ? "3rem" : "0rem"} + ${isSearchVisible ? "1rem" : "0rem"})`,
				height:
					contentCardVerticalOffset !== null
						? `calc(${contentCardVerticalOffset.toString()}px + 3rem)`
						: undefined
			}}
		/>
	)
}

export default BackgroundImage
