import { useLocation } from "react-router-dom"

/**
 * A React hook to retrieve the state passed to the current route.
 * @returns The state object passed to the current route, or null if no state was passed.
 * @example const state = useRouterState<CraftEntryType>()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useRouterState = <Type extends object>(): Type | null => {
	const location = useLocation()
	const state = location.state satisfies Type as Type | null | undefined

	return state ?? null
}
