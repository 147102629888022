import { motion, type HTMLMotionProps } from "framer-motion"
import type { JSX } from "react"
import { useRouteError } from "react-router-dom"

import App from "~/app"
import InnerError from "~/components/inner-error"
import Layout from "~/components/layout/layout"
import { isRouteError } from "~/types/error/router"

/**
 * The page rendered upon client-side router errors.
 * @returns The React component. This should only be used by the router.
 * @example <ErrorPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ErrorPage = ({ ...props }: HTMLMotionProps<"div">): JSX.Element => {
	const error = useRouteError()

	// Since this page is rendered by the router at the top level, it's outside the <App> & <Layout> component tree, so we have to add them back around our <main> :)

	return (
		<App {...props}>
			<Layout>
				<motion.main
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					className={`flex flex-grow flex-col justify-end overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
					{isRouteError(error) ? (
						<InnerError
							heading="Route Error"
							message={
								error.status === 404
									? "Sorry, this page does not exist. Please return to the previous page."
									: "Sorry, we're having trouble showing this page. Please try again later."
							}
							error={error}
						/>
					) : (
						<InnerError
							heading="Route Error"
							message="Sorry, we're having trouble showing this page. Please try again later."
						/>
					)}
				</motion.main>
			</Layout>
		</App>
	)
}

export default ErrorPage
