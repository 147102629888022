import { motion, type HTMLMotionProps } from "framer-motion"
import { useCallback, useEffect, useMemo, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"

import BackgroundImage from "~/components/background-image"
import ContentCard from "~/components/content-card"
import InnerError from "~/components/inner-error"
import Metadata from "~/components/metadata"
import SkeletonLoader from "~/components/skeleton-loader"
import Button from "~/components/standard/button"
import Image from "~/components/standard/image"
import { useBreadcrumbsFurthestDropDownIndexDispatch } from "~/hooks/use-breadcrumbs"
import { useHomeEntry } from "~/hooks/use-home-entry"
import { useMediaQueries } from "~/hooks/use-media-query"
import { useSelectionsDispatch, useSiteSelection } from "~/hooks/use-selections"
import { useStateParameterValidation } from "~/hooks/use-state-parameter-validation"
import { BackgroundImagePath } from "~/images"
import { generateCraftEntryRoute, generateSelectRoleRoute, Routes } from "~/router/routes"
import type { AnyRole } from "~/types/api/roles"
import type { PartialEntry } from "~/types/api/routes/partial-entries"
import type { CraftEntryState } from "~/types/state"

const ActionButton = ({
	partialEntry,
	role,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "onClick" | "role" | "type"> & {
	partialEntry: PartialEntry
	role: AnyRole
}): JSX.Element => {
	const { updateRole } = useSelectionsDispatch()
	const siteHandle = useSiteSelection()

	const navigate = useNavigate()

	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (siteHandle === null) {
			console.warn("No site selection yet?!")
			return
		}

		updateRole(role)

		navigate(generateCraftEntryRoute(siteHandle, role, partialEntry.slug), {
			state: {
				partialEntry
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, updateRole, siteHandle, partialEntry, role])

	return (
		<Button
			{...props}
			onClick={onClick}
			className={`items-center gap-x-4 rounded-xl bg-logo-purple p-4 text-left text-lg text-white hover:bg-purple-alt active:bg-purple-alt/95 ${props.className ?? ""}`.trimEnd()}
		/>
	)
}

/**
 * The page for selecting a role.
 * This is the 2nd step after selecting an NHS trust.
 * @returns The React component. This should only be used by the router.
 * @example <SelectRolePage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const SelectRolePage = ({ ...props }: HTMLMotionProps<"main">): JSX.Element => {
	const { isLandscape } = useMediaQueries()

	const siteHandle = useSiteSelection()

	const { isSiteMismatch, isError: isSitesError, error: sitesError } = useStateParameterValidation()

	const {
		partialEntries,
		entry: homeEntry,
		isReady: isHomeReady,
		isError: isHomeError,
		error: homeError
	} = useHomeEntry({
		siteHandle: siteHandle,
		skip: isSiteMismatch
	})

	// No need to cast type - https://devblogs.microsoft.com/typescript/announcing-typescript-5-5-beta/#inferred-type-predicates
	const actionButtons = useMemo(() => {
		if (homeEntry === null || partialEntries === null) return null

		return homeEntry.primaryNavigation
			.map(button => {
				if (button.theLink.value === null) {
					console.warn(`No target Craft CMS entry ID for action button '${button.text}'?!`)
					return null
				}

				const entryId = parseInt(button.theLink.value, 10)
				if (isNaN(entryId)) {
					console.warn(
						`Invalid Craft CMS entry ID '${button.theLink.value}' for action button '${button.text}'?!`
					)
					return null
				}

				// eslint-disable-next-line @typescript-eslint/naming-convention
				const targetPartialEntry = partialEntries.find(({ entry_id }) => entry_id === entryId)
				if (targetPartialEntry === undefined) {
					console.warn(
						`Unable to find partial Craft CMS entry with ID '${entryId.toString()}' for action button '${button.text}'?!`
					)
					return null
				}

				return {
					button,
					partialEntry: targetPartialEntry
				}
			})
			.filter(button => button !== null)
	}, [homeEntry, partialEntries])

	// Reset the breadcrumbs when we land on this page
	const { setFurthestDropDownIndex } = useBreadcrumbsFurthestDropDownIndexDispatch()
	useEffect(() => {
		setFurthestDropDownIndex(0)
	}, [setFurthestDropDownIndex])

	// Throw them back to the trust selection page if they've not selected a trust yet...
	if (siteHandle === null) return <Navigate to={Routes.SelectTrust} />

	return (
		<motion.main
			{...props}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={`flex flex-grow flex-col justify-end ${props.className ?? ""}`.trimEnd()}>
			{/* eslint-disable-next-line no-nested-ternary */}
			{isHomeReady && homeEntry === null ? (
				<InnerError
					heading="Not Found"
					message="Sorry, this page does not exist. Please return to the previous page."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ParentReadingToChild}
							accessibilityDescription="A parent reading a book to their child."
						/>
					}
				/>
			) : (isHomeError && homeError !== null) || (isSitesError && sitesError !== null) ? (
				<InnerError
					heading="API Error"
					message="Sorry, we're having problems communicating with our server. Please try again later."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ParentReadingToChild}
							accessibilityDescription="A parent reading a book to their child."
						/>
					}
					error={homeError ?? sitesError}
				/>
			) : (
				<>
					<Metadata title="Select Role" path={generateSelectRoleRoute(siteHandle)} />

					{/* Feature */}
					{/* TODO: Try entry's headerImage first, then fallback to a static image! */}
					<BackgroundImage
						imageUrl={BackgroundImagePath.ParentReadingToChild}
						accessibilityDescription="A parent reading a book to their child."
					/>

					{/* Card */}
					<ContentCard heading="What is your role?" isFloating={true}>
						<SkeletonLoader
							rowCount={VITE_HANDI_ACTION_BUTTON_COUNTS[siteHandle] ?? 3}
							isLoading={!isHomeReady || homeEntry === null || actionButtons === null || isSiteMismatch}
							innerClassName={isLandscape === true ? "gap-x-4 flex-row" : "gap-y-4 flex-col"}
							rowClassName="h-20">
							{actionButtons?.map((actionButton, index) => (
								<ActionButton
									key={index}
									label={actionButton.button.text}
									icon={
										// eslint-disable-next-line no-nested-ternary
										actionButton.button.pwaIcon !== undefined &&
										actionButton.button.pwaIcon !== null ? (
											<ReactSVG
												src={actionButton.button.pwaIcon}
												className="aspect-square h-12 min-h-12 w-12 min-w-12 fill-white"
											/>
										) : actionButton.button.icon !== null ? (
											<Image
												src={actionButton.button.icon}
												alt={`An icon representing ${actionButton.button.text}.`}
												className="aspect-square h-12 min-h-12 w-12 min-w-12"
											/>
										) : undefined
									}
									partialEntry={actionButton.partialEntry}
									// eslint-disable-next-line no-nested-ternary
									role={index === 0 ? "parents" : index === 1 ? "professionals" : "hospital"}
									className={isLandscape === true ? "flex-1" : undefined}
								/>
							))}
						</SkeletonLoader>
					</ContentCard>
				</>
			)}
		</motion.main>
	)
}

export default SelectRolePage
