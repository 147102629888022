import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"

import { useCraftEntries } from "~/hooks/use-craft-entries"
import { useCraftEntry } from "~/hooks/use-craft-entry"
import type { Entry } from "~/types/api/routes/entry"
import type { PartialEntry } from "~/types/api/routes/partial-entries"

/**
 * Fetches the Home entry for the given site from Craft CMS.
 * @param params The parameters.
 * @param params.siteHandle The handle of the selected site (NHS trust).
 * @param params.skip Whether to skip fetching the entry.
 * @returns The Craft entry and its state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useHomeEntry = ({
	siteHandle,
	skip = false
}: {
	siteHandle: string | null
	skip?: boolean
}): {
	partialEntries: PartialEntry[] | null

	partialEntry: PartialEntry | null
	entry: Entry | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const {
		partialEntries,
		partialEntry,
		isReady: isPartialReady,
		isSuccess: isPartialSuccess,
		isError: isPartialError,
		error: partialError
	} = useCraftEntries({
		siteHandle,
		filter: ({ slug, section }) => slug === siteHandle && section === "homePages",
		skip
	})

	const {
		entry,
		isReady: isEntryReady,
		isSuccess: isEntrySuccess,
		isError: isEntryError,
		error: entryError
	} = useCraftEntry({
		siteHandle,
		id: partialEntry?.entry_id ?? null,
		skip
	})

	return {
		partialEntries,

		partialEntry,
		entry,

		isReady: isPartialReady && isEntryReady,
		isSuccess: isPartialSuccess && isEntrySuccess,
		isError: isPartialError || isEntryError,

		error: partialError ?? entryError
	}
}
