import { useMemo } from "react"
import { useParams } from "react-router-dom"

import { orNull } from "~/helpers/primitives"

/**
 * Retrieves the URL parameters for the current route.
 * @returns The URL parameters.
 * @example const { site, role, slug } = useParameters()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useParameters = (): {
	siteParameter: string | null
	roleParameter: string | null
	slugParameter: string | null
} => {
	const parameters = useParams()

	const siteParameter = useMemo<string | null>(() => orNull(parameters["site"]), [parameters])
	const roleParameter = useMemo<string | null>(() => orNull(parameters["role"]), [parameters])
	const slugParameter = useMemo<string | null>(() => orNull(parameters["slug"]), [parameters])

	return {
		siteParameter,
		roleParameter,
		slugParameter
	}
}
