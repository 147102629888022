/**
 * Returns the given value, or null if the value is undefined or an empty string.
 * @param value The value to check.
 * @returns The value or null if it's undefined or an empty string.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const orNull = (value?: string | null): string | null =>
	value !== undefined && value !== null && value !== "" ? value : null

/**
 * Recursively transforms all properties of an object that match a filter.
 * @param object The object to transform.
 * @param filter The filter to apply to each property.
 * @param transform The transformation to apply to each filtered property.
 * @returns The transformed object.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const deepTransformObjectProperties = <Type extends object>(
	object: Type,
	filter: (key: string, value: unknown) => boolean,
	transform: (key: string, value: unknown) => unknown
): Type => {
	const transformed: Record<string, unknown> = {}

	for (const [key, value] of Object.entries(object)) {
		if (Array.isArray(value)) {
			transformed[key] = value.map<unknown>(item =>
				typeof item === "object" && item !== null
					? deepTransformObjectProperties(item as Record<string, unknown>, filter, transform)
					: item
			)
			continue
		}

		if (typeof value === "object" && value !== null) {
			transformed[key] = deepTransformObjectProperties(value as Record<string, unknown>, filter, transform)
			continue
		}

		if (filter(key, value)) {
			transformed[key] = transform(key, value)
			continue
		}

		transformed[key] = value
	}

	return transformed as Type
}

/**
 * Splits an array into chunks of a specified size.
 * The last chunk may contain fewer items than the specified size.
 * @param input The array to split.
 * @param size The size of each chunk.
 * @returns An array of chunks.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const chunk = <Type>(input: Type[], size: number): Type[][] =>
	input.reduce<Type[][]>((result, item, index): Type[][] => {
		const chunkIndex = Math.floor(index / size)

		result[chunkIndex] ||= []
		result[chunkIndex]?.push(item)

		return result
	}, [])
