import { ContentBlockTypes, type ContentBlock, type ContentBlocks } from "~/types/api/common/content-block"

/**
 * A location choice.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Location {
	/**
	 * The display text of the choice.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	label: "Dentists" | "Doctors" | "Health Professionals" | "Hospitals"

	/**
	 * The value of the choice.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	value: "dentists" | "doctors" | "health-professionals" | "hospitals"

	/**
	 * Whether this choice was selected in Craft.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	selected: boolean

	/**
	 * @todo Not sure what this is for.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	valid: boolean
}

/**
 * A content block that contains a map (?) with a location choice.
 * @todo Not sure what this is meant to do!
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface ProximityMap extends ContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.ProximityMap

	/**
	 * The display text for the map.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	mapTitle: string

	/**
	 * The location choice.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	locations: Location
}

/**
 * Ensures the given content block is a proximity map block.
 * @param block Any content block.
 * @returns Whether the content block is a proximity map block.
 * @example if (isProximityMapContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isProximityMapContentBlock = (block: ContentBlocks): block is ProximityMap =>
	block.type === ContentBlockTypes.ProximityMap
