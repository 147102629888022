import { useCallback, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import { useAboutEntry } from "~/hooks/use-about-entry"
import { useMediaQueries } from "~/hooks/use-media-query"
import { useRoleSelection, useSiteSelection } from "~/hooks/use-selections"
import { useSymptomsEntry } from "~/hooks/use-symptoms-entry"
import { generateCraftEntryRoute, generateSelectRoleRoute } from "~/router/routes"
import type { CraftEntryState } from "~/types/state"

import InfoIcon from "~/assets/icons/info.svg?react"
import PractitionersIcon from "~/assets/icons/practitioners.svg?react"
import SymptomsIcon from "~/assets/icons/symptoms.svg?react"

/**
 * The standard quick navigation bar for the application.
 * This includes quick actions for changing trust, returning to the symptoms page, and viewing trust information.
 * @returns The React component.
 * @example <QuickNavigation />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const QuickNavigation = ({ ...props }: ComponentPropsWithRef<"footer">): JSX.Element => {
	const { isLandscape, isMediumWidth } = useMediaQueries()

	const navigate = useNavigate()

	const siteHandle = useSiteSelection()
	const roleHandle = useRoleSelection()

	const { partialEntry: symptomsPartialEntry } = useSymptomsEntry({
		siteHandle: siteHandle,
		roleHandle: roleHandle
	})

	const { partialEntry: aboutPartialEntry } = useAboutEntry({
		siteHandle: siteHandle
	})

	// Navigates to the select trust page...
	const onChangeRoleClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (siteHandle === null) {
			console.warn("No site selection yet?!")
			return
		}

		navigate(generateSelectRoleRoute(siteHandle))
	}, [navigate, siteHandle])

	// Navigates to the symptoms page...
	const onSymptomsClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (siteHandle === null) {
			console.warn("No site selection yet?!")
			return
		}

		if (roleHandle === null) {
			console.warn("No role selection yet?!")
			return
		}

		if (symptomsPartialEntry === null) {
			console.warn(`Could not find the Symptoms Craft CMS entry for role '${roleHandle}'!`)
			return
		}

		navigate(generateCraftEntryRoute(siteHandle, roleHandle, symptomsPartialEntry.slug), {
			state: {
				partialEntry: symptomsPartialEntry
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, siteHandle, roleHandle, symptomsPartialEntry])

	// Navigates to the about page (same one accessible from settings page)...
	const onAboutTrustClick = useCallback<MouseEventHandler<SVGSVGElement>>(() => {
		if (siteHandle === null) {
			console.warn("No site selection yet?!")
			return
		}

		if (aboutPartialEntry === null) {
			console.warn("Could not find the About Craft CMS entry!")
			return
		}

		// Special case where about is a "role"
		navigate(generateCraftEntryRoute(siteHandle, "about", aboutPartialEntry.slug), {
			state: {
				partialEntry: aboutPartialEntry
			} satisfies CraftEntryState as CraftEntryState
		})
	}, [navigate, siteHandle, aboutPartialEntry])

	return (
		<div
			{...props}
			className={`sticky bottom-0 m-auto flex flex-row justify-between rounded-full border border-control-border bg-white shadow-sm ${isLandscape === true ? "w-1/2 p-3" : `p-4 ${isMediumWidth === true ? "mx-2" : "w-full"}`} ${props.className ?? ""}`.trimEnd()}>
			<PractitionersIcon
				className={`aspect-square overflow-visible rounded-full p-3 duration-hover ${siteHandle !== null ? "bg-purple-alt/20 fill-logo-purple hover:cursor-pointer hover:bg-purple-alt/30 active:bg-purple-alt/40" : "cursor-not-allowed bg-control-disabled-background fill-control-disabled-text"}`}
				width={48}
				height={48}
				onClick={onChangeRoleClick}
			/>

			<SymptomsIcon
				className={`aspect-square overflow-visible rounded-full p-3 duration-hover ${symptomsPartialEntry !== null ? "bg-purple-alt/20 fill-logo-purple hover:cursor-pointer hover:bg-purple-alt/30 active:bg-purple-alt/40" : "cursor-not-allowed bg-control-disabled-background fill-control-disabled-text"}`.trimEnd()}
				width={48}
				height={48}
				onClick={onSymptomsClick}
			/>

			<InfoIcon
				className={`aspect-square rounded-full p-3 duration-hover ${aboutPartialEntry !== null ? "bg-purple-alt/20 fill-logo-purple hover:cursor-pointer hover:bg-purple-alt/30 active:bg-purple-alt/40" : "cursor-not-allowed bg-control-disabled-background fill-control-disabled-text"}`.trimEnd()}
				width={48}
				height={48}
				onClick={onAboutTrustClick}
			/>
		</div>
	)
}

export default QuickNavigation
