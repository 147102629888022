import type { Choice } from "~/types/api/content-blocks/choice"
import type { Icons } from "~/types/api/content-blocks/icons"
import type { Image } from "~/types/api/content-blocks/image"
import type { Images } from "~/types/api/content-blocks/images"
import type { Links } from "~/types/api/content-blocks/links"
import type { Map } from "~/types/api/content-blocks/map"
import type { ProximityMap } from "~/types/api/content-blocks/proximity-map"
import type { Text } from "~/types/api/content-blocks/text"

/* eslint-disable @typescript-eslint/no-shadow */
export enum ContentBlockTypes {
	IconList = "IconList",
	ImageBlock = "ImageBlock",
	Links = "Links",
	Map = "Map",
	PhotoList = "PhotoList",
	ProximityMap = "ProximityMap",
	Text = "Text",
	YesNoLinks = "YesNoLinks"
}

/**
 * Common properties among all types of content blocks.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface ContentBlock {
	/**
	 * The unique identifier of the content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	id: number

	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type:
		| ContentBlockTypes.IconList
		| ContentBlockTypes.ImageBlock
		| ContentBlockTypes.Links
		| ContentBlockTypes.Map
		| ContentBlockTypes.PhotoList
		| ContentBlockTypes.ProximityMap
		| ContentBlockTypes.Text
		| ContentBlockTypes.YesNoLinks
}

/**
 * A content block that can be styled.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface StyledContentBlock extends ContentBlock {
	/**
	 * The style of the content block.
	 * @todo Is #41B6E6 the same as highlighted?
	 * @todo What even is highlighted?
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	style: "#41B6E6" | "default" | "green" | "highlighted" | "orange" | "red"
}

/**
 * All types of content blocks.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export type ContentBlocks = Choice | Icons | Image | Images | Links | Map | ProximityMap | Text
