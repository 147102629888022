import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { useDispatch, useSelector, type TypedUseSelectorHook } from "react-redux"

import { api } from "~/api/client"
import breadcrumbs from "~/state/slices/breadcrumbs"
import layout from "~/state/slices/layout"
import selections from "~/state/slices/selections"

/**
 * A Redux store for managing global state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const store = configureStore({
	devTools: import.meta.env.DEV,

	reducer: {
		selections,
		breadcrumbs,
		layout,
		[api.reducerPath]: api.reducer
	},

	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware)
})

setupListeners(store.dispatch)

/**
 * The structure of the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export type Store = ReturnType<typeof store.getState>

/**
 * A React hook for retrieving values from any slice within the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useReduxSelector: TypedUseSelectorHook<Store> = useSelector

/**
 * A React hook for updating values in any slice within the Redux store.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useReduxDispatch: () => typeof store.dispatch = useDispatch
