import { motion, type HTMLMotionProps } from "framer-motion"
import { useEffect, useMemo, type JSX } from "react"
import { ReactSVG } from "react-svg"
import terminal from "virtual:terminal"

import BackgroundImage from "~/components/background-image"
import ContentBlocks from "~/components/content-blocks/content-blocks"
import ContentCard from "~/components/content-card"
import InnerError from "~/components/inner-error"
import Metadata from "~/components/metadata"
import QuickNavigation from "~/components/quick-navigation"
import SkeletonLoader from "~/components/skeleton-loader"
import { useCraftAdmin } from "~/hooks/use-craft-admin"
import { useCraftEntries } from "~/hooks/use-craft-entries"
import { useCraftEntry } from "~/hooks/use-craft-entry"
import { useParameters } from "~/hooks/use-parameters"
import { useRouterState } from "~/hooks/use-router-state"
import { useRoleSelection, useSiteSelection } from "~/hooks/use-selections"
import { useStateParameterValidation } from "~/hooks/use-state-parameter-validation"
import { BackgroundImagePath } from "~/images"
import { setCurrentEntryId } from "~/state/slices/breadcrumbs"
import { useReduxDispatch } from "~/state/store"
import { PageStyles } from "~/types/api/common/page-styles"
import type { CraftEntryState } from "~/types/state"

import TickIcon from "~/assets/icons/tick.svg?react"
import WarningIcon from "~/assets/icons/warning.svg?react"

/**
 * The page for rendering Craft CMS entries.
 * @returns The React component. This should only be used by the router.
 * @example <CraftEntryPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const CraftEntryPage = ({ ...props }: HTMLMotionProps<"main">): JSX.Element => {
	const siteHandle = useSiteSelection()
	const roleHandle = useRoleSelection()

	const { slugParameter } = useParameters()
	const routerState = useRouterState<CraftEntryState>()

	const { isSiteMismatch, isRoleMismatch, isError: isSitesError, error: sitesError } = useStateParameterValidation()

	// Fetch the entry from URL parameters if we've got no state
	const { partialEntry, isReady: isPartialReady } = useCraftEntries({
		siteHandle,
		filter: ({ slug, section }) => slug === slugParameter && section === roleHandle,
		skip: roleHandle === null || slugParameter === null || isSiteMismatch || isRoleMismatch
	})

	const partialEntryId = useMemo<number | null>(
		() => routerState?.partialEntry.entry_id ?? partialEntry?.entry_id ?? null,
		[routerState, partialEntry]
	)

	// Fetch the entry using the partial passed from state or fetched from URL parameters
	const {
		entry,
		isReady: isEntryReady,
		isError: isEntryError,
		error: entryError
	} = useCraftEntry({
		siteHandle,
		id: partialEntryId,
		skip: isSiteMismatch || isRoleMismatch
	})

	// If the page only has a single image content block, we'll use it as the background image
	/*
	const imageForBackground = useMemo(() => {
		const imageContentBlocks = entry?.contentBlocks.filter(isImageContentBlock)
		if (imageContentBlocks === undefined || imageContentBlocks.length !== 1) return null

		const [imageContentBlock] = imageContentBlocks
		if (imageContentBlock === undefined) return null

		return imageContentBlock
	}, [entry])
	*/

	const dispatch = useReduxDispatch()

	// Update the current entry ID for breadcrumbs
	useEffect(() => {
		const entryId = routerState?.partialEntry.entry_id ?? partialEntry?.entry_id ?? null
		if (entryId === null) return

		dispatch(setCurrentEntryId(entryId))
	}, [dispatch, routerState, partialEntry])

	// Dump Craft CMS URL to the Vite console for ease of development
	const { adminUrl } = useCraftAdmin({
		partialEntry: routerState?.partialEntry ?? partialEntry ?? null,
		siteHandle,
		roleHandle
	})
	if (entry !== null && partialEntryId !== null && adminUrl !== null && import.meta.env.DEV)
		terminal.info(
			`We're on Craft CMS entry '${entry.title.toString()}' (${partialEntryId.toString()}) @ ${adminUrl.toString()}`
		)

	return (
		<motion.main
			{...props}
			// key={
			// 	routerState?.partialEntry.entry_id ??
			// 	`${siteHandle?.toString() ?? "n/a"}-${roleHandle?.toString() ?? "n/a"}-${slugParameter?.toString() ?? "n/a"}`
			// }
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={`flex flex-grow flex-col justify-end overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
			{/* eslint-disable-next-line no-nested-ternary */}
			{isPartialReady && partialEntry === null ? (
				<InnerError
					heading="Not Found"
					message="Sorry, this page does not exist. Please return to the previous page."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ChildWithSymptoms}
							accessibilityDescription="A parent checking on a child that has symptoms."
							className="h-[34rem]"
						/>
					}
				/>
			) : (isEntryError && entryError !== null) || (isSitesError && sitesError !== null) ? (
				<InnerError
					heading="API Error"
					message="Sorry, we're having problems communicating with our server. Please try again later."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ChildWithSymptoms}
							accessibilityDescription="A parent checking on a child that has symptoms."
							className="h-[34rem]"
						/>
					}
					error={entryError ?? sitesError}
				/>
			) : (
				<>
					{isEntryReady && entry !== null && <Metadata title={entry.title} />}

					{/* Feature */}
					{/* <BackgroundImage
						imageUrl={imageForBackground?.image ?? BackgroundImagePath.ChildWithSymptoms}
						accessibilityDescription={
							imageForBackground?.imageTitle ?? "A parent checking on a child that has symptoms."
						}
					/> */}
					<BackgroundImage
						//ref={backgroundImageReference}
						imageUrl={BackgroundImagePath.ChildWithSymptoms}
						accessibilityDescription="A parent checking on a child that has symptoms."
						className="h-[34rem]"
					/>

					{/* Card */}
					{/* <AnimatePresence mode="wait"> */}
					<ContentCard
						// key={partialEntryId}
						heading={entry?.title ?? "Loading..."}
						pageStyle={entry?.pageStyle}
						constrainDimensions={true}
						dynamicBackgroundImage={false}
						icon={
							/* eslint-disable no-nested-ternary */
							entry?.pwaIcon !== undefined && entry.pwaIcon !== null ? (
								<ReactSVG src={entry.pwaIcon} className="h-full w-full fill-white" />
							) : entry?.pageStyle === PageStyles.Red || entry?.pageStyle === PageStyles.Amber ? (
								<WarningIcon className="h-full w-full fill-white" />
							) : entry?.pageStyle === PageStyles.Green ? (
								<TickIcon className="h-full w-full fill-white" />
							) : undefined
						}>
						<SkeletonLoader
							isLoading={!isEntryReady || entry === null || isSiteMismatch || isRoleMismatch}
							innerClassName="flex-col">
							{entry !== null && (
								<ContentBlocks
									blocks={entry.contentBlocks}
									pageStyle={entry.pageStyle}
									entry={entry}
									//ignoreImage={imageForBackground !== null}
								/>
							)}
						</SkeletonLoader>

						<QuickNavigation />
					</ContentCard>
					{/* </AnimatePresence> */}
				</>
			)}
		</motion.main>
	)
}

export default CraftEntryPage
