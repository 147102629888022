import { useCallback, type ComponentPropsWithRef, type JSX, type MouseEventHandler } from "react"

import Button from "~/components/standard/button"
import type { ProximityMap } from "~/types/api/content-blocks/proximity-map"

import MapIcon from "~/assets/icons/map.svg?react"

/**
 * A content block that renders a proximity map.
 * @returns A React component.
 * @example <ProximityMapContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ProximityMapContentBlock = ({
	block,

	...props
}: Omit<ComponentPropsWithRef<typeof Button>, "label" | "onClick"> & { block: ProximityMap }): JSX.Element => {
	const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		// eslint-disable-next-line default-case
		switch (block.locations.value) {
			case "doctors":
				window.open("https://google.com/maps/search/Doctors+near+me", "_blank", "noopener noreferrer")
				break
			case "dentists":
				window.open("https://google.com/maps/search/Dentists+near+me", "_blank", "noopener noreferrer")
				break
			case "health-professionals":
				window.open(
					"https://google.com/maps/search/Health+Professionals+near+me",
					"_blank",
					"noopener noreferrer"
				)
				break
			case "hospitals":
				window.open("https://google.com/maps/search/Hospitals+near+me", "_blank", "noopener noreferrer")
				break
		}
	}, [block])

	return (
		<Button
			{...props}
			label={block.mapTitle}
			icon={
				<MapIcon className="aspect-square h-9 min-h-9 w-9 min-w-9 rounded-full bg-logo-purple fill-white p-1.5" />
			}
			onClick={onClick}
			className="items-center gap-x-4 rounded-xl bg-purple-alt/15 p-4 text-left text-logo-purple hover:bg-purple-alt/25 active:bg-purple-alt/35"
		/>
	)
}

export default ProximityMapContentBlock
