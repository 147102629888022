/**
 * Paths to non-imported background images.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.5
 */
export enum BackgroundImagePath {
	ChildWithSymptoms = "/images/backgrounds/child-with-symptoms.webp",
	HandPaintingChild = "/images/backgrounds/hand-painting-child.webp",
	HappyFamily = "/images/backgrounds/happy-family.webp",
	HighTemperature = "/images/backgrounds/high-temperature.webp",
	ParentReadingToChild = "/images/backgrounds/parent-reading-to-child.webp"
}

/**
 * Paths to non-imported logo images.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.5
 */
export enum LogoPath {
	Transparent = "/images/logos/transparent.webp",
	White = "/images/logos/white.webp"
}
