import { captureMessage } from "@sentry/react"
import { useEffect, type ComponentPropsWithRef, type JSX } from "react"

import type { Images } from "~/types/api/content-blocks/images"

/**
 * A content block that renders multiple images.
 * @returns A React component.
 * @example <ImagesContentBlock block={block} />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const ImagesContentBlock = ({ block, ...props }: ComponentPropsWithRef<"div"> & { block: Images }): JSX.Element => {
	useEffect(() => {
		captureMessage(`Image content block not implemented yet!`, {
			level: "info",
			extra: { ...block }
		})
	})

	return (
		<div {...props}>
			<p className="font-mono text-logo-magenta">TODO: Images content block ({block.id})</p>
		</div>
	)
}

export default ImagesContentBlock
