import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useFetchEntryQuery } from "~/api/client"
import { orNull } from "~/helpers/primitives"
import type { Entry } from "~/types/api/routes/entry"

/**
 * Fetches a single Craft CMS entry for a site (NHS trust).
 * @param params The parameters.
 * @param params.id The identifier of the entry to fetch.
 * @param params.siteHandle The handle of the site to fetch the entry for. If not provided, the selected site is used.
 * @param params.skip Whether to skip fetching the entry.
 * @returns The entry & API request status.
 * @example const { entry } = useCraftEntry()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useCraftEntry = ({
	id,
	siteHandle,
	skip = false
}: {
	id: number | null
	siteHandle: string | null
	skip?: boolean
}): {
	entry: Entry | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { currentData, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useFetchEntryQuery(
		{
			siteHandle: orNull(siteHandle) ?? "you-should-never-see-this",
			entryId: id ?? -20241007
		},
		{
			skip: skip || orNull(siteHandle) === null || id === null
		}
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	return {
		entry: currentData ?? null,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
