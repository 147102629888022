import { createBrowserRouter, type RouteObject } from "react-router-dom"

import App from "~/app"
import Layout from "~/components/layout/layout"
import { IS_STAGING } from "~/constants"
import CraftEntryPage from "~/pages/craft-entry"
import DemoEntryPage from "~/pages/demo-entry"
import ErrorPage from "~/pages/error"
import IndexPage from "~/pages/index"
import SelectRolePage from "~/pages/select-role"
import SelectTrustPage from "~/pages/select-trust"
import SettingsPage from "~/pages/settings"
import { Routes } from "~/router/routes"

const layoutRoutes: RouteObject[] = [
	{
		path: Routes.Index,
		element: <IndexPage />
	},
	{
		path: Routes.SelectTrust,
		element: <SelectTrustPage />
	},
	{
		path: Routes.SelectRole,
		element: <SelectRolePage />
	},
	{
		path: Routes.CraftEntry,
		element: <CraftEntryPage />
	}
]

if (import.meta.env.DEV || IS_STAGING)
	layoutRoutes.push({
		path: "/demo-entry",
		element: <DemoEntryPage />
	})

/**
 * The router that handles client-side navigating between pages.
 * @see https://reactrouter.com/en/main/start/tutorial
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const router = createBrowserRouter([
	{
		path: Routes.Index,
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: Routes.Index,
				element: <Layout />,
				children: layoutRoutes
			},
			{
				path: Routes.Settings,
				element: <SettingsPage />
			}
		]
	}
])
