import { useLayoutEffect, useMemo, useRef, type ComponentPropsWithRef, type JSX } from "react"

import ChoiceContentBlock from "~/components/content-blocks/types/choice"
import IconsContentBlock from "~/components/content-blocks/types/icons"
import ImageContentBlock from "~/components/content-blocks/types/image"
import ImagesContentBlock from "~/components/content-blocks/types/images"
import LinksContentBlock from "~/components/content-blocks/types/links"
import MapContentBlock from "~/components/content-blocks/types/map"
import ProximityMapContentBlock from "~/components/content-blocks/types/proximity-map"
import TextContentBlock from "~/components/content-blocks/types/text"
import type { ContentBlocks as _ContentBlocks } from "~/types/api/common/content-block"
import type { PageStyles } from "~/types/api/common/page-styles"
import { isChoiceContentBlock } from "~/types/api/content-blocks/choice"
import { isIconsContentBlock } from "~/types/api/content-blocks/icons"
import { isImageContentBlock } from "~/types/api/content-blocks/image"
import { isImagesContentBlock } from "~/types/api/content-blocks/images"
import { isLinksContentBlock } from "~/types/api/content-blocks/links"
import { isMapContentBlock } from "~/types/api/content-blocks/map"
import { isProximityMapContentBlock } from "~/types/api/content-blocks/proximity-map"
import { isTextContentBlock } from "~/types/api/content-blocks/text"
import type { Entry } from "~/types/api/routes/entry"

const ContentBlocks = ({
	blocks,
	ignoreImage = false,
	pageStyle,
	entry,

	...props
}: ComponentPropsWithRef<"div"> & {
	blocks: _ContentBlocks[]
	ignoreImage?: boolean
	pageStyle?: PageStyles
	entry?: Entry
}): JSX.Element => {
	const containerReference = useRef<HTMLDivElement>(null)

	// TODO: This is a very crude way of side-by-side aligning the image content blocks with 50% width. It would be better if they had their own content block so they could be aligned via a parent flex row.
	useLayoutEffect(() => {
		if (containerReference.current === null) return

		Array.from(containerReference.current.children)
			.reduce<HTMLDivElement[][]>((_consecutiveElements, childElement) => {
				if (childElement.tagName.toLowerCase() !== "div") return _consecutiveElements // Skip if the element isn't a <div>
				if (childElement.attributes.getNamedItem("data-requires-merge") === null) return _consecutiveElements // Skip if the element doesn't require merging

				const nextElement = childElement.nextElementSibling
				if (nextElement === null) return _consecutiveElements // Skip if there's no next element
				if (nextElement.tagName.toLowerCase() !== "div") return _consecutiveElements // Skip if the next element isn't a <div>
				if (nextElement.attributes.getNamedItem("data-requires-merge") === null) return _consecutiveElements // Skip if the next element doesn't require merging

				_consecutiveElements.push([childElement as HTMLDivElement, nextElement as HTMLDivElement])

				return _consecutiveElements
			}, [])
			.forEach(group => {
				const [primaryElement, secondaryElement] = group
				if (primaryElement === undefined || secondaryElement === undefined) return // This should never happen

				Array.from(secondaryElement.children).forEach(child => {
					child.classList.remove("opacity-0", "cursor-wait", "duration-image")
					primaryElement.appendChild(child.cloneNode(true))
					child.remove()
				})
			})
	}, [containerReference, blocks])

	const contentBlocks = useMemo(
		() => (ignoreImage ? blocks.filter(block => !isImageContentBlock(block)) : blocks),
		[blocks, ignoreImage]
	)

	return (
		<div
			{...props}
			ref={containerReference}
			className={`flex flex-grow flex-col gap-y-4 ${props.className ?? ""}`.trimEnd()}>
			{contentBlocks.map((block, index) => {
				if (isTextContentBlock(block))
					return <TextContentBlock key={index} block={block} pageStyle={pageStyle} heading={entry?.title} />
				if (isImageContentBlock(block)) return <ImageContentBlock key={index} block={block} />
				if (isImagesContentBlock(block)) return <ImagesContentBlock key={index} block={block} />
				if (isChoiceContentBlock(block)) return <ChoiceContentBlock key={index} block={block} />
				if (isIconsContentBlock(block)) return <IconsContentBlock key={index} block={block} />
				if (isLinksContentBlock(block)) return <LinksContentBlock key={index} block={block} />
				if (isMapContentBlock(block)) return <MapContentBlock key={index} block={block} />
				if (isProximityMapContentBlock(block)) return <ProximityMapContentBlock key={index} block={block} />
				return (
					<p key={index} className="font-mono font-bold text-red-500">
						Unknown content block!
					</p>
				)
			})}
		</div>
	)
}

export default ContentBlocks
