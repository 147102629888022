import { ContentBlockTypes, type ContentBlocks, type StyledContentBlock } from "~/types/api/common/content-block"

/**
 * A content block that contains text.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export interface Text extends StyledContentBlock {
	/**
	 * The type of content block.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	type: ContentBlockTypes.Text

	/**
	 * The text content of the block.
	 * This can contain HTML (including <br/> line breaks & empty <p></p> elements), new lines (\n), etc.
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 0.1.0
	 */
	text: string

	/**
	 * The raw content of the block.
	 * This should closely resemble the rendered content, but inline links will be structured as '{type:id@X:url||https://example.com/entry/...}'
	 * This was not part of the original API!
	 * @author Jay Hunter <jh@yello.studio>
	 * @since 4.2.0
	 */
	raw?: string
}

/**
 * Ensures the given content block is a text block.
 * @param block Any content block.
 * @returns Whether the content block is a text block.
 * @example if (isTextContentBlock(block)) { ... }
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const isTextContentBlock = (block: ContentBlocks): block is Text => block.type === ContentBlockTypes.Text
