import { AnimatePresence } from "framer-motion"
import { useMemo, type ComponentPropsWithRef, type JSX } from "react"
import { Outlet, useLocation } from "react-router-dom"

import Header from "~/components/layout/header"
import { useParameters } from "~/hooks/use-parameters"
import { Routes } from "~/router/routes"

/**
 * The standard base layout for the application.
 * This is required as a separate component instead of being in <App /> so the settings page can be rendered without the header, but still inherit from the <App /> component in the router.
 * @returns The React component.
 * @example <Layout />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const Layout = ({ children, ...props }: ComponentPropsWithRef<"div">): JSX.Element => {
	const { pathname } = useLocation()
	const { siteParameter, roleParameter, slugParameter } = useParameters()

	// Checks whether we're on the select trust page...
	const isSelectTrustPage = useMemo<boolean>(() => pathname === Routes.SelectTrust.valueOf(), [pathname])

	// Checks whether we're on the select role page...
	const isSelectRolePage = useMemo<boolean>(() => {
		if (siteParameter === null) return false
		return pathname === Routes.SelectRole.replace(":site", siteParameter)
	}, [siteParameter, pathname])

	// Checks whether we're on a Craft entry page...
	const isCraftEntryPage = useMemo<boolean>(() => {
		if (siteParameter === null || roleParameter === null || slugParameter === null) return false
		return (
			pathname ===
			Routes.CraftEntry.replace(":site", siteParameter)
				.replace(":role", roleParameter)
				.replace(":slug", slugParameter)
		)
	}, [siteParameter, roleParameter, slugParameter, pathname])

	return (
		<div
			{...props}
			className={`z-0 flex flex-grow flex-col justify-between gap-y-4 overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
			<Header
				showBackButton={!isSelectTrustPage}
				showSettingsButton={!isSelectTrustPage}
				showSearch={isSelectRolePage}
				showBreadcrumbs={isCraftEntryPage}
				//showGrab={isCraftEntryPage}
			/>

			<AnimatePresence mode="wait">
				{children ?? <Outlet />} {/* This is <main> */}
			</AnimatePresence>
		</div>
	)
}

export default Layout
