import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useFetchSitesQuery } from "~/api/client"
import type { Site } from "~/types/api/routes/sites"

/**
 * Fetches all sites (NHS trusts).
 * @param params The parameters.
 * @param params.filter A filter function to apply to the sites. This populates the site result, but does NOT change the sites result.
 * @param params.skip Whether to skip fetching sites.
 * @returns The sites & API request status.
 * @example const { sites } = useCraftSites()
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useCraftSites = ({
	filter,
	skip = false
}: {
	filter?: (site: Site) => boolean
	skip?: boolean
}): {
	sites: Site[] | null
	site: Site | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const { currentData, error, isUninitialized, isFetching, isLoading, isSuccess, isError } = useFetchSitesQuery(
		undefined,
		{
			skip
		}
	)

	const site = useMemo<Site | null>(
		() => (filter !== undefined ? (currentData?.find(filter) ?? null) : null),
		[currentData, filter]
	)

	const isReady = useMemo<boolean>(
		() => !isFetching && !isLoading && !isUninitialized,
		[isFetching, isLoading, isUninitialized]
	)

	return {
		sites: currentData ?? null,
		site,

		isReady,
		isSuccess,
		isError,

		error: isError ? error : null
	}
}
