import { useMemo } from "react"

import { useCraftEntries } from "~/hooks/use-craft-entries"
import { setFurthestDropDownIndex } from "~/state/slices/breadcrumbs"
import { useReduxDispatch, useReduxSelector } from "~/state/store"
import type { PartialEntry } from "~/types/api/routes/partial-entries"

/**
 * Finds the breadcrumbs leading to the current entry.
 * @returns The breadcrumbs, or null if no data yet. Ordered from the root entry to the current entry.
 * @example const breadcrumbs = useBreadcrumbs({ 8192, siteHandle, roleHandle })
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.7
 */
export const useBreadcrumbs = ({
	entryId,

	siteHandle,
	roleHandle
}: {
	entryId: number | null

	siteHandle: string | null
	roleHandle: string | null
}): {
	breadcrumbs: PartialEntry[] | null
	hasBreadcrumbs: boolean
} => {
	const { partialEntries } = useCraftEntries({
		siteHandle
	})

	// Reduce to just the entries for this role
	const ourPartialEntries = useMemo(
		() => partialEntries?.filter(({ section }) => section === roleHandle) ?? null,
		[partialEntries, roleHandle]
	)

	// Find the current entry
	const currentPartialEntry = useMemo(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		() => ourPartialEntries?.find(({ entry_id }) => entry_id === entryId) ?? null,
		[ourPartialEntries, entryId]
	)

	const breadcrumbs = useMemo(() => {
		if (ourPartialEntries === null || currentPartialEntry === null) return null // Don't continue if we don't have the data yet

		if (currentPartialEntry.parent === undefined) return [] // We're not on the new API yet :(
		if (currentPartialEntry.parent === null) return [currentPartialEntry] // We're the top-level entry

		const parentPartialEntries: PartialEntry[] = []
		let parentPartialEntry: PartialEntry | null = currentPartialEntry // Start with ourselves so we're at the end

		// Repeat until we've found the top-level entry
		while (parentPartialEntry !== null) {
			parentPartialEntries.unshift(parentPartialEntry) // Add to the start, not the end

			parentPartialEntry =
				// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-loop-func
				ourPartialEntries.find(({ entry_id }) => entry_id === parentPartialEntry?.parent) ?? null
		}

		return parentPartialEntries
	}, [ourPartialEntries, currentPartialEntry])

	// No need to render if we don't have any breadcrumbs
	const hasBreadcrumbs = useMemo<boolean>(() => breadcrumbs !== null && breadcrumbs.length > 1, [breadcrumbs])

	return {
		breadcrumbs,
		hasBreadcrumbs
	}
}

/**
 * Updates the furthest drop-down index of the breadcrumbs.
 * This is used to determine which breadcrumbs to display/where the cut off point is.
 * @returns A React callback to update the furthest drop-down index.
 * @example const { setFurthestDropDownIndex } = useBreadcrumbsFurthestDropDownIndexDispatch()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.1.0
 */
export const useBreadcrumbsFurthestDropDownIndexDispatch = (): {
	setFurthestDropDownIndex: (index: number) => void
} => {
	const dispatch = useReduxDispatch()

	return {
		setFurthestDropDownIndex: (index: number) => dispatch(setFurthestDropDownIndex(index))
	}
}

/**
 * Retrieves the furthest drop-down index of the breadcrumbs.
 * This is used to determine which breadcrumbs to display/where the cut off point is.
 * @returns The furthest drop-down index.
 * @example const furthestDropDownIndex = useBreadcrumbsFurthestDropDownIndexSelection()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.1.0
 */
export const useBreadcrumbsFurthestDropDownIndexSelection = (): number =>
	useReduxSelector(({ breadcrumbs }) => breadcrumbs.furthestDropDownIndex)
