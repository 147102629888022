import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo } from "react"

import { useCraftEntry } from "~/hooks/use-craft-entry"
import { useHomeEntry } from "~/hooks/use-home-entry"
import type { Entry } from "~/types/api/routes/entry"
import type { PartialEntry } from "~/types/api/routes/partial-entries"

/**
 * Fetches the About entry for the given site from Craft CMS.
 * @param params The parameters.
 * @param params.siteHandle The handle of the selected site (NHS trust).
 * @param params.skip Whether to skip fetching the entry.
 * @returns The Symptoms entry and its state.
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
export const useAboutEntry = ({
	siteHandle,
	skip = false
}: {
	siteHandle: string | null
	skip?: boolean
}): {
	partialEntries: PartialEntry[] | null

	partialEntry: PartialEntry | null
	entry: Entry | null

	isReady: boolean
	isSuccess: boolean
	isError: boolean

	error: FetchBaseQueryError | SerializedError | null
} => {
	const {
		partialEntries,
		entry: homeEntry,
		isReady: isHomeReady,
		isError: isHomeError,
		error: homeError
	} = useHomeEntry({
		siteHandle,
		skip
	})

	const aboutEntryId = useMemo<number | null>(() => {
		if (homeEntry === null) return null // Do nothing if we don't have the required data yet

		const [secondaryNavigation] = homeEntry.secondaryNavigation
		if (homeEntry.secondaryNavigation.length < 1 || secondaryNavigation === undefined) {
			console.warn("No secondary navigation items found in home Craft CMS entry!")
			return null
		}

		const navigationTarget = secondaryNavigation.theLink.value
		if (navigationTarget === null) {
			console.warn("Secondary navigation has no target!")
			return null
		}

		const entryId = parseInt(navigationTarget, 10)
		if (isNaN(entryId)) {
			console.warn(`Invalid Craft CMS entry ID '${navigationTarget}' in secondary navigation!`)
			return null
		}

		return entryId
	}, [homeEntry])

	const aboutPartialEntry = useMemo(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		() => partialEntries?.find(({ entry_id, section }) => entry_id === aboutEntryId && section === "about") ?? null,
		[partialEntries, aboutEntryId]
	)

	const {
		entry: aboutEntry,
		isReady: isAboutReady,
		isSuccess: isAboutSuccess,
		isError: isAboutError,
		error: aboutError
	} = useCraftEntry({
		siteHandle,
		id: aboutEntryId,
		skip
	})

	return {
		partialEntries,

		partialEntry: aboutPartialEntry,
		entry: aboutEntry,

		isReady: isHomeReady && isAboutReady,
		isSuccess: isHomeReady && isAboutSuccess,
		isError: isHomeError || isAboutError,

		error: homeError ?? aboutError
	}
}
